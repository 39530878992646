import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Collapsible from 'react-collapsible'
import { Link } from 'react-router'
import { Field, FieldArray } from 'redux-form'
import Sticky from 'react-sticky-state'
import Tabs, { TabPane } from 'rc-tabs'
import TabContent from 'rc-tabs/lib/TabContent'
import SwipeableInkTabBar from 'rc-tabs/lib/SwipeableInkTabBar'

import Tooltip from '../common/Tooltip'
import LabelWithTooltip from '../common/LabelWithTooltip'
import { CheckboxField, InputField, SelectField, TimeField } from '../../components/common/fields/FormFields'
import IncrementableTable from '../../components/common/IncrementableTable'
import Trainer from '../../containers/bot/Trainer'
import TopicList from './TopicList'
import ScenarioTree from './ScenarioTree'
import MenuEdit from './MenuEdit'
import FeedbackEdit from './FeedbackEdit'
import FaqList from './FaqList'
import IntegrationIndex from '../integration/IntegrationIndex'
import TaskIndex from '../task/TaskIndex'
import EntityIndex from '../entity/EntityIndex'
import PnpTopicIndex from '../pnp_topic/PnpTopicIndex'
import OAuthEdit from './OAuthEdit'

import TextField from '../common/fields/TextField'
import { isPermitted } from '../../helpers/permission'
import { getCredentials } from '../../helpers/sessionHelper'

export class BotEditComponent extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    type: PropTypes.string,
    bot: PropTypes.object.isRequired,
    domains: PropTypes.array.isRequired,
    topics: PropTypes.array,
    availableTopics: PropTypes.array,
    pnpTopics: PropTypes.array,
    faqs: PropTypes.array.isRequired,
    faqFiles: PropTypes.array.isRequired,
    integrations: PropTypes.array,
    tasks: PropTypes.array,
    entities: PropTypes.array,
    handleSave: PropTypes.func.isRequired,
    handleCopy: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    handleExport: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
    botEditForm: PropTypes.object,
    office365OAuthClients: PropTypes.array,
    salesforceOAuthClients: PropTypes.array,
    tableStates: PropTypes.object,
    updateTableState: PropTypes.func.isRequired,
    topicsStyle: PropTypes.string,
    useExportImport: PropTypes.bool,
    maxScenarios: PropTypes.number.isRequired,
    updateTopicsStyle: PropTypes.func.isRequired,
    resetLeaveHook: PropTypes.func.isRequired,
    onChangeMenuImageFile: PropTypes.func.isRequired,
    addErrorNoticeImageLoading: PropTypes.func.isRequired,
    resetMenuImage: PropTypes.func.isRequired,
  }

  static defaultProps = {
    type: 'topics',
    botEditForm: {},
    domains: [],
    topics: [],
    availableTopics: [],
    faqs: [],
    faqFiles: [],
    integrations: [],
    tasks: [],
    entities: [],
    office365OAuthClients: [],
    salesforceOAuthClients: [],
    topicsStyle: 'table',
  }

  /**
   * Renders elements to edit and whow a bot
   * @return {ReactElement} Elements to show and edit a bot
   */
  render() {
    const { t } = this.context
    const {
      bot,
      domains,
      availableTopics,
      handleSubmit,
      handleSave,
      handleCopy,
      handleDelete,
      isSubmitting,
      handleExport,
      botEditForm,
      office365OAuthClients,
      salesforceOAuthClients,
      useExportImport,
      onChangeMenuImageFile,
      addErrorNoticeImageLoading,
      resetMenuImage,
    } = this.props
    const { allow_fulltext_search } = getCredentials(this.context)

    const useFirstTopic = !!botEditForm.use_first_topic
    const defaultBehavior = botEditForm.default_behavior
    const useFeedback = !!botEditForm.use_feedback
    const isOptionalFeedback = !!botEditForm.is_optional_feedback
    const isRequiredFeedbackReason = !!botEditForm.is_required_feedback_reason
    const useOffice365 = !!botEditForm.use_office365
    const useSalesforce = !!botEditForm.use_salesforce

    return (
      <div>
        {/* Sttings */}
        <form className="text-left bot-edit" onSubmit={handleSave}>
          {/* Bot name */}
          <div className="form-group">
            <LabelWithTooltip htmlFor="name" className="dm-title" name="bot.name" />
            <Field
              name="name"
              component={InputField}
              type="text"
              className="form-control dm-form-control"
              maxLength="100"
            />
          </div>

          {/* First message */}
          <LabelWithTooltip htmlFor="first_message" className="dm-title" name="bot.firstMessage" />
          <div className="form-group">
            <TextField
              name="first_message"
              className="form-control dm-form-control"
              disabled={useFirstTopic}
              minRows={4}
              nullable={true}
            />
          </div>

          {/* Topic, FAQ and Integration Table */}
          {bot.id && this.renderResourceTabs()}

          {/* Menu settings */}
          {bot.id && (
            <MenuEdit
              formValues={botEditForm.menu}
              domains={domains}
              topics={availableTopics}
              defaultBehavior={defaultBehavior}
              defaultOpened={true}
              defaultImageUrl={(bot.menu || {}).image_url}
              onChangeMenuImageFile={onChangeMenuImageFile}
              addErrorNoticeImageLoading={addErrorNoticeImageLoading}
              resetMenuImage={resetMenuImage}
            />
          )}

          {/* Feedback */}
          {bot.id && isPermitted('feature_feedback', this.context) && (
            <FeedbackEdit
              enabled={useFeedback}
              defaultOpened={true}
              isOptional={isOptionalFeedback}
              isRequiredReason={isRequiredFeedbackReason}
            />
          )}

          {/* User variable settings (hidden on default) */}
          {bot.id && (
            <div className="memory-definitions">
              <Collapsible
                trigger={
                  <span>
                    {t('bot.memoryDefinitions.title')}
                    <Tooltip name="bot.tooltip.memoryDefinitions.title" />
                  </span>
                }
                open={bot.memory_definitions && bot.memory_definitions.length > 0}
                transitionTime={300}
                overflowWhenOpen="visible"
              >
                <div className="with-indent">
                  <FieldArray name="memory_definitions" component={this.renderMemoryDefinitions} />
                </div>
              </Collapsible>
            </div>
          )}

          {/* Chatbot constant settings (hidden on default) */}
          {bot.id && (
            <div className="constant-definitions">
              <Collapsible
                trigger={
                  <span>
                    {t('bot.constantDefinitions.title')}
                    <Tooltip name="bot.tooltip.constantDefinitions.title" />
                  </span>
                }
                open={botEditForm.constant_definitions && botEditForm.constant_definitions.length > 0}
                transitionTime={300}
                overflowWhenOpen="visible"
              >
                <div className="with-indent">
                  <FieldArray name="constant_definitions" component={this.renderConstantDefinitions} />
                </div>
              </Collapsible>
            </div>
          )}

          {/* Advanced settings (hidden on default) */}
          {bot.id && (
            <div className="advanced-menu">
              <Collapsible
                trigger={t('bot.advancedSettings.title')}
                transitionTime={300}
                overflowWhenOpen="visible"
              >
                <div className="with-indent">
                  {isPermitted('feature_oauth', this.context) && (
                    <OAuthEdit
                      office365OAuthClients={office365OAuthClients}
                      salesforceOAuthClients={salesforceOAuthClients}
                      enabledOffice365={useOffice365}
                      enabledSalesforce={useSalesforce}
                    />
                  )}
                  {this.renderUseGPT()}
                  {this.renderCustomizeForClassification()}
                  {isPermitted('feature_operator', this.context) && this.renderCustomizeForOperator()}
                  {this.renderClassificationFailsTopicForTopic()}
                  {isPermitted('feature_faq_file', this.context) &&
                    this.renderClassificationFailsTopicForFaq()}
                  {this.renderFirstScenario()}
                  {allow_fulltext_search && this.renderUseFulltextSearch()}
                  {isPermitted('feature_advanced_machine_learning', this.context) &&
                    this.renderUseAdvancedMachineLearning()}
                  {this.renderSkipEchoMessage()}
                  {this.renderDigitMasking()}
                  {this.renderUseResetFeature()}
                  {this.renderUseUndoFeature()}
                  {this.renderLanguageSetting()}
                </div>
              </Collapsible>
            </div>
          )}
        </form>

        {/* Buttons to edit bot (sticked to the page buttom) */}
        <Sticky>
          <div className="sticky bottom">
            <div className="dm-sticky-buttons">
              <button
                type="button"
                className="btn btn-primary dm-btn"
                onClick={handleSave}
                disabled={isSubmitting}
              >
                {t('common.save')}
              </button>
              <Trainer
                bot={bot}
                topics={availableTopics}
                handleSubmit={handleSubmit}
                handleSave={handleSave}
                isSubmitting={isSubmitting}
              />
              {bot.id && [
                <button
                  key="copy"
                  type="button"
                  className="btn btn-primary dm-btn"
                  onClick={handleCopy}
                  disabled={isSubmitting}
                >
                  {t('common.copy')}
                </button>,
                <button
                  key="delete"
                  type="button"
                  className="btn btn-danger dm-btn"
                  onClick={handleDelete}
                  disabled={isSubmitting}
                >
                  {t('common.delete')}
                </button>,
              ]}
              {bot.id && useExportImport && isPermitted('feature_import_export', this.context) && (
                <button
                  type="button"
                  className="btn btn-primary dm-btn"
                  onClick={handleExport}
                  disabled={isSubmitting}
                >
                  {t('common.export')}
                </button>
              )}
            </div>
          </div>
        </Sticky>
      </div>
    )
  }

  renderResourceTabs = () => {
    const { t, router } = this.context
    const {
      type,
      bot,
      faqs,
      faqFiles,
      integrations,
      tasks,
      entities,
      pnpTopics,
      tableStates,
      updateTableState,
      handleAdd,
    } = this.props
    const session = this.context.store.getState().session

    const tabs = []

    //  Topics
    const topicTab = <div id="topics">{t('topic.listTitle')}</div>
    tabs.push(
      <TabPane tab={topicTab} data-tab="topics" key="topics" forceRender={true}>
        {this.renderTopics()}
      </TabPane>
    )

    //  FAQs
    if (isPermitted('feature_faq_file', this.context)) {
      const faqTab = <div id="faqs">{t('faqfile.title')}</div>
      tabs.push(
        <TabPane tab={faqTab} data-tab="faqs" key="faqs">
          <FaqList
            bot={bot}
            faqs={faqs}
            faqFiles={faqFiles}
            tableState={tableStates['faq']}
            updateTableState={updateTableState}
            handleAdd={handleAdd}
          />
        </TabPane>
      )
    }

    //  Integrations
    if (isPermitted('feature_integration', this.context)) {
      const integrationTab = <div id="integrations">{t('integration.title')}</div>
      tabs.push(
        <TabPane tab={integrationTab} data-tab="integrations" key="integrations">
          <IntegrationIndex
            bot={bot}
            integrations={integrations}
            tableState={tableStates['integration']}
            updateTableState={updateTableState}
          />
        </TabPane>
      )
    }

    //  Entities
    const entityTab = <div id="entities">{t('entity.title')}</div>
    tabs.push(
      <TabPane tab={entityTab} data-tab="entities" key="entities">
        <EntityIndex
          bot={bot}
          entities={entities}
          tableState={tableStates['entity']}
          updateTableState={updateTableState}
        />
      </TabPane>
    )

    //  Tasks
    if (isPermitted('feature_task', this.context)) {
      const taskTab = <div id="tasks">{t('task.title')}</div>
      tabs.push(
        <TabPane tab={taskTab} data-tab="tasks" key="tasks">
          <TaskIndex
            bot={bot}
            tasks={tasks}
            tableState={tableStates['task']}
            updateTableState={updateTableState}
          />
        </TabPane>
      )
    }

    //  Topics for LINE Phone number push
    if (isPermitted('feature_line_pnp', this.context) && session.role === 'owner') {
      const pnpTopicTab = <div id="pnp_topics">{t('pnpTopic.title')}</div>
      tabs.push(
        <TabPane tab={pnpTopicTab} data-tab="pnp_topics" key="pnp_topics">
          <PnpTopicIndex
            bot={bot}
            topics={pnpTopics}
            tableState={tableStates['pnpTopic']}
            updateTableState={updateTableState}
          />
        </TabPane>
      )
    }

    return (
      <Tabs
        className="dm-tabs dm-bot-resources"
        defaultActiveKey={type}
        renderTabBar={() => <SwipeableInkTabBar pageSize={tabs.length} speed={10} />}
        renderTabContent={() => <TabContent animatedWithMargin />}
        onChange={key => {
          router.replace({
            pathname: `/bots/${bot.id}/${key}`,
            state: { ignoreBlocking: true },
          })
          this.props.resetLeaveHook()
        }}
      >
        {tabs}
      </Tabs>
    )
  }

  renderTopics = () => {
    const {
      bot,
      domains,
      topics,
      tableStates,
      topicsStyle,
      updateTableState,
      updateTopicsStyle,
      handleAdd,
      maxScenarios,
    } = this.props
    let component
    if (topicsStyle === 'tree') {
      component = (
        <ScenarioTree
          bot={bot}
          domains={domains}
          topics={topics}
          topicsStyle={topicsStyle}
          maxScenarios={maxScenarios}
          updateTopicsStyle={updateTopicsStyle}
        />
      )
    } else {
      component = (
        <TopicList
          bot={bot}
          topics={topics}
          tableState={tableStates['topic']}
          updateTableState={updateTableState}
          handleAdd={handleAdd}
          topicsStyle={topicsStyle}
          maxScenarios={maxScenarios}
          updateTopicsStyle={updateTopicsStyle}
        />
      )
    }

    return component
  }

  renderUseGPT = () => {
    const { t } = this.context
    const { bot, botEditForm, isSubmitting } = this.props
    const useGPT = !!botEditForm.use_gpt
    const openaiType = botEditForm.openai_type

    const openAITypes = []

    if (isPermitted('feature_openai_default', this.context)) {
      openAITypes.push({ code: 'default', label: t('bot.advancedSettings.gpt.types.systemDefault') })
    }

    openAITypes.push({ code: 'azure', label: t('bot.advancedSettings.gpt.types.azureOpenAIService') })
    openAITypes.push({ code: 'openai', label: t('bot.advancedSettings.gpt.types.openAI') })

    if (isPermitted('feature_amazon_bedrock', this.context)) {
      openAITypes.push({ code: 'bedrock', label: t('bot.advancedSettings.gpt.types.bedrock') })
    }

    return (
      <div>
        <div className="form-group form-inline checkbox">
          <Field type="checkbox" className="form-control m-0" name="use_gpt" component={CheckboxField} />
          <LabelWithTooltip htmlFor="use_gpt" name="bot.advancedSettings.gpt.title" />
        </div>
        {useGPT && (
          <>
            <div className="form-group row dm-form-group menu-paragraph">
              <LabelWithTooltip
                htmlFor="openai_type"
                name="bot.advancedSettings.gpt.type"
                className="col-sm-3"
              />
              <div className="col-sm-5">
                <Field
                  name="openai_type"
                  items={openAITypes}
                  valueKey="code"
                  displayKey="label"
                  className="form-control dm-form-control"
                  component={SelectField}
                  disabled={isSubmitting}
                />
              </div>
            </div>
            {openaiType === 'azure' && (
              <>
                <div className="form-group row dm-form-group menu-paragraph">
                  <LabelWithTooltip
                    htmlFor="openai_endpoint"
                    className="col-sm-3"
                    name="bot.advancedSettings.gpt.endpoint"
                  />
                  <div className="col-sm-5">
                    <Field
                      name="openai_endpoint"
                      type="text"
                      className="form-control dm-form-control"
                      maxLength="255"
                      component={InputField}
                      placeholder={t('bot.advancedSettings.gpt.endpointPlaceholder')}
                    />
                  </div>
                </div>
                <div className="form-group row dm-form-group menu-paragraph">
                  <LabelWithTooltip
                    htmlFor="openai_api_key"
                    className="col-sm-3"
                    name="bot.advancedSettings.gpt.apiKey"
                  />
                  <div className="col-sm-5">
                    <Field
                      name="openai_api_key"
                      type="password"
                      className="form-control dm-form-control"
                      maxLength="32"
                      component={InputField}
                      placeholder={bot.use_gpt ? '********' : ''}
                      autoComplete="new-password"
                    />
                  </div>
                </div>
                <div className="form-group row dm-form-group menu-paragraph">
                  <LabelWithTooltip
                    htmlFor="openai_embedding_deployment"
                    className="col-sm-3"
                    name="bot.advancedSettings.gpt.embeddingDeployment"
                  />
                  <div className="col-sm-5">
                    <Field
                      name="openai_embedding_deployment"
                      type="text"
                      className="form-control dm-form-control"
                      maxLength="255"
                      component={InputField}
                    />
                  </div>
                </div>
                <div className="form-group row dm-form-group menu-paragraph">
                  <LabelWithTooltip
                    htmlFor="openai_completion_deployment"
                    className="col-sm-3"
                    name="bot.advancedSettings.gpt.completionDeployment"
                  />
                  <div className="col-sm-5">
                    <Field
                      name="openai_completion_deployment"
                      type="text"
                      className="form-control dm-form-control"
                      maxLength="255"
                      component={InputField}
                    />
                  </div>
                </div>
              </>
            )}
            {openaiType === 'openai' && (
              <>
                <div className="form-group row dm-form-group menu-paragraph">
                  <LabelWithTooltip
                    htmlFor="openai_api_key"
                    className="col-sm-3"
                    name="bot.advancedSettings.gpt.apiKey"
                  />
                  <div className="col-sm-5">
                    <Field
                      name="openai_api_key"
                      type="password"
                      className="form-control dm-form-control"
                      maxLength="64"
                      component={InputField}
                      placeholder={bot.use_gpt ? '********' : ''}
                      autoComplete="new-password"
                    />
                  </div>
                </div>
                <div className="form-group row dm-form-group menu-paragraph">
                  <LabelWithTooltip
                    htmlFor="openai_embedding_model"
                    className="col-sm-3"
                    name="bot.advancedSettings.gpt.embeddingModel"
                  />
                  <div className="col-sm-5">
                    <Field
                      name="openai_embedding_model"
                      type="text"
                      className="form-control dm-form-control"
                      maxLength="255"
                      component={InputField}
                      placeholder={t('bot.advancedSettings.gpt.embeddingModelPlaceholder')}
                    />
                  </div>
                </div>
                <div className="form-group row dm-form-group menu-paragraph">
                  <LabelWithTooltip
                    htmlFor="openai_completion_model"
                    className="col-sm-3"
                    name="bot.advancedSettings.gpt.completionModel"
                  />
                  <div className="col-sm-5">
                    <Field
                      name="openai_completion_model"
                      type="text"
                      className="form-control dm-form-control"
                      maxLength="255"
                      component={InputField}
                      placeholder={t('bot.advancedSettings.gpt.completionModelPlaceholder')}
                    />
                  </div>
                </div>
              </>
            )}
            {openaiType === 'bedrock' && (
              <>
                <div className="form-group row dm-form-group menu-paragraph">
                  <LabelWithTooltip
                    htmlFor="aws_region"
                    className="col-sm-3"
                    name="bot.advancedSettings.gpt.awsRegion"
                  />
                  <div className="col-sm-5">
                    <Field
                      name="aws_region"
                      type="text"
                      className="form-control dm-form-control"
                      maxLength="255"
                      component={InputField}
                      placeholder={t('bot.advancedSettings.gpt.awsRegionPlaceholder')}
                    />
                  </div>
                </div>
                <div className="form-group row dm-form-group menu-paragraph">
                  <LabelWithTooltip
                    htmlFor="aws_completion_model"
                    className="col-sm-3"
                    name="bot.advancedSettings.gpt.awsBedrockCompletionModel"
                  />
                  <div className="col-sm-5">
                    <Field
                      name="aws_completion_model"
                      type="text"
                      className="form-control dm-form-control"
                      maxLength="255"
                      component={InputField}
                      placeholder={t('bot.advancedSettings.gpt.awsBedrockCompletionModelPlaceholder')}
                    />
                  </div>
                </div>
                <div className="form-group row dm-form-group menu-paragraph">
                  <LabelWithTooltip
                    htmlFor="aws_access_key_id"
                    className="col-sm-3"
                    name="bot.advancedSettings.gpt.awsAccessKeyId"
                  />
                  <div className="col-sm-5">
                    <Field
                      name="aws_access_key_id"
                      type="password"
                      className="form-control dm-form-control"
                      maxLength="20"
                      component={InputField}
                      placeholder={bot.use_gpt ? '********' : ''}
                      autoComplete="new-password"
                    />
                  </div>
                </div>
                <div className="form-group row dm-form-group menu-paragraph">
                  <LabelWithTooltip
                    htmlFor="aws_secret_access_key"
                    className="col-sm-3"
                    name="bot.advancedSettings.gpt.awsSecretAccessKey"
                  />
                  <div className="col-sm-5">
                    <Field
                      name="aws_secret_access_key"
                      type="password"
                      className="form-control dm-form-control"
                      maxLength="40"
                      component={InputField}
                      placeholder={bot.use_gpt ? '********' : ''}
                      autoComplete="new-password"
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    )
  }

  renderMemoryDefinitions = ({ fields }) => {
    const { t } = this.context
    const columns = [
      {
        Header: t('bot.memoryDefinitions.name'),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.name`}
            className="form-control dm-form-control"
            component={InputField}
          />
        ),
      },
      {
        Header: t('bot.memoryDefinitions.value'),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.value`}
            className="form-control dm-form-control"
            component={InputField}
          />
        ),
      },
    ]

    return <IncrementableTable fields={fields} columns={columns} />
  }

  renderConstantDefinitions = ({ fields }) => {
    const { t } = this.context
    const columns = [
      {
        Header: t('bot.constantDefinitions.name'),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.name`}
            className="form-control dm-form-control"
            maxLength="30"
            component={InputField}
          />
        ),
      },
      {
        Header: (
          <React.Fragment>
            {t('bot.constantDefinitions.value')}
            <Tooltip name="bot.tooltip.constantDefinitions.value" />
          </React.Fragment>
        ),
        Cell: (name, rowIndex) => (
          <Field
            type={(fields.get(rowIndex) || {}).is_secret ? 'password' : 'text'}
            name={`${name}.value`}
            className="form-control dm-form-control"
            maxLength="1000"
            component={InputField}
            placeholder={(fields.get(rowIndex) || {}).is_secret ? '********' : null}
          />
        ),
      },
      {
        Header: index => (
          <th className="is-secret" key={index}>
            {t('bot.constantDefinitions.secret')}
          </th>
        ),
        Cell: (name, _rowIndex, columnIndex) => (
          <td className="is-secret" key={columnIndex}>
            <Field
              type="checkbox"
              name={`${name}.is_secret`}
              className="form-control m-2"
              component={CheckboxField}
            />
          </td>
        ),
      },
    ]

    return <IncrementableTable fields={fields} columns={columns} />
  }

  renderFirstScenario = () => {
    const { availableTopics, botEditForm } = this.props
    const useFirstTopic = !!botEditForm.use_first_topic
    return (
      <div>
        <div className="form-group form-inline checkbox">
          <Field
            type="checkbox"
            name="use_first_topic"
            className="form-control m-0"
            component={CheckboxField}
          />
          <LabelWithTooltip htmlFor="use_first_topic" name="bot.advancedSettings.firstTopic.title" />
        </div>
        {useFirstTopic && (
          <div className="form-group row dm-form-group menu-paragraph">
            <LabelWithTooltip
              htmlFor="first_topic_id"
              className="col-sm-3"
              name="bot.advancedSettings.firstTopic.targetTopic"
            />
            <div className="col-sm-5">
              <Field
                name="first_topic_id"
                items={availableTopics}
                valueKey="id"
                displayKey="name"
                className="form-control dm-form-control col-sm-3"
                component={SelectField}
                empty={true}
                parse={value => value && parseInt(value, 10)}
                order="asc"
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  renderCustomizeForClassification = () => {
    const { t } = this.context
    const { botEditForm } = this.props
    const useCustomMessages = !!botEditForm.use_custom_messages
    return (
      <div>
        <div className="form-group form-inline checkbox">
          <Field
            type="checkbox"
            className="form-control m-0"
            name="use_custom_messages"
            component={CheckboxField}
          />
          <LabelWithTooltip htmlFor="use_custom_messages" name="bot.advancedSettings.customMessage.title" />
        </div>
        {useCustomMessages && (
          <div>
            <div className="form-group row dm-form-group menu-paragraph">
              <LabelWithTooltip
                htmlFor="template_breakdown_classification_failure"
                className="col-sm-3"
                name="bot.advancedSettings.customMessage.noEstimatedMessage.label"
              />
              <div className="col-sm-5">
                <TextField
                  name="template_breakdown_classification_failure"
                  className="form-control dm-form-control"
                  minRows={3}
                  nullable={true}
                  ignoreValidation={(value, allValues) => !allValues.use_custom_messages}
                  placeholder={t('bot.advancedSettings.customMessage.noEstimatedMessage.defaultMessage')}
                />
              </div>
            </div>
            <div className="form-group row dm-form-group menu-paragraph">
              <LabelWithTooltip
                htmlFor="operator_calling"
                className="col-sm-3"
                name="bot.advancedSettings.customMessage.operatorCalling.label"
              />
              <div className="col-sm-5">
                <TextField
                  name="operator_calling"
                  className="form-control dm-form-control"
                  minRows={3}
                  nullable={true}
                  ignoreValidation={(value, allValues) => !allValues.use_custom_messages}
                  placeholder={t('bot.advancedSettings.customMessage.operatorCalling.defaultMessage')}
                />
              </div>
            </div>
            <div className="form-group row dm-form-group menu-paragraph">
              <LabelWithTooltip
                htmlFor="operator_pickup"
                className="col-sm-3"
                name="bot.advancedSettings.customMessage.operatorPickup.label"
              />
              <div className="col-sm-5">
                <TextField
                  name="operator_pickup"
                  className="form-control dm-form-control"
                  minRows={3}
                  nullable={true}
                  ignoreValidation={(value, allValues) => !allValues.use_custom_messages}
                  placeholder={t('bot.advancedSettings.customMessage.operatorPickup.defaultMessage')}
                />
              </div>
            </div>
            <div className="form-group row dm-form-group menu-paragraph">
              <LabelWithTooltip
                htmlFor="operator_hangup"
                className="col-sm-3"
                name="bot.advancedSettings.customMessage.operatorHangup.label"
              />
              <div className="col-sm-5">
                <TextField
                  name="operator_hangup"
                  className="form-control dm-form-control"
                  minRows={3}
                  nullable={true}
                  ignoreValidation={(value, allValues) => !allValues.use_custom_messages}
                  placeholder={t('bot.advancedSettings.customMessage.operatorHangup.defaultMessage')}
                />
              </div>
            </div>
            <div className="form-group row dm-form-group menu-paragraph">
              <LabelWithTooltip
                htmlFor="operator_non_business_hours"
                className="col-sm-3"
                name="bot.advancedSettings.customMessage.operatorNonBusinessHours.label"
              />
              <div className="col-sm-5">
                <TextField
                  name="operator_non_business_hours"
                  className="form-control dm-form-control"
                  minRows={3}
                  nullable={true}
                  disabled={!botEditForm.enable_operator_business_hours}
                  ignoreValidation={(value, allValues) => !allValues.use_custom_messages}
                  placeholder={t(
                    'bot.advancedSettings.customMessage.operatorNonBusinessHours.defaultMessage'
                  )}
                />
              </div>
            </div>
            <div className="form-group row dm-form-group menu-paragraph">
              <LabelWithTooltip
                htmlFor="application_republish"
                className="col-sm-3"
                name="bot.advancedSettings.customMessage.applicationRepublish.label"
              />
              <div className="col-sm-5">
                <TextField
                  name="application_republish"
                  className="form-control dm-form-control"
                  minRows={4}
                  nullable={true}
                  ignoreValidation={(value, allValues) => !allValues.use_custom_messages}
                  placeholder={t('bot.advancedSettings.customMessage.applicationRepublish.defaultMessage')}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  renderCustomizeForOperator = () => {
    const { t } = this.context
    const { botEditForm } = this.props
    const useCustomOperator = !!botEditForm.use_custom_operator
    return (
      <div>
        <div className="form-group form-inline checkbox">
          <Field
            type="checkbox"
            className="form-control m-0"
            name="use_custom_operator"
            component={CheckboxField}
          />
          <LabelWithTooltip htmlFor="use_custom_operator" name="bot.advancedSettings.customOperator.title" />
        </div>
        {useCustomOperator && (
          <div>
            <div className="form-group row dm-form-group menu-paragraph">
              <LabelWithTooltip
                htmlFor="calling_operator_button"
                className="col-sm-3"
                name="bot.advancedSettings.customOperator.callingButton.label"
              />
              <div className="col-sm-5">
                <Field
                  name="calling_operator_button"
                  type="text"
                  className="form-control dm-form-control"
                  maxLength="20"
                  component={InputField}
                  placeholder={t('bot.advancedSettings.customOperator.callingButton.default')}
                />
              </div>
            </div>
            <div className="form-group row dm-form-group menu-paragraph">
              <LabelWithTooltip
                htmlFor="calling_operator_message"
                className="col-sm-3"
                name="bot.advancedSettings.customOperator.callingOperatorMessage.label"
              />
              <div className="col-sm-9">
                <Field
                  name="calling_operator_message"
                  type="text"
                  className="form-control dm-form-control"
                  maxLength="280"
                  component={InputField}
                  placeholder={t('bot.advancedSettings.customOperator.callingOperatorMessage.defaultMessage')}
                />
              </div>
            </div>
            <div className="form-group row dm-form-group menu-paragraph">
              <LabelWithTooltip
                htmlFor="operator_typing_message"
                className="col-sm-3"
                name="bot.advancedSettings.customOperator.operatorTypingMessage.label"
              />
              <div className="col-sm-9">
                <Field
                  name="operator_typing_message"
                  type="text"
                  className="form-control dm-form-control"
                  maxLength="280"
                  component={InputField}
                  placeholder={t('bot.advancedSettings.customOperator.operatorTypingMessage.defaultMessage')}
                />
              </div>
            </div>
            <div className="form-group dm-form-group form-inline row menu-paragraph">
              <Field
                type="checkbox"
                className="form-control m-0"
                name="is_display_operator_icon"
                component={CheckboxField}
              />
              <LabelWithTooltip
                htmlFor="is_display_operator_icon"
                name="bot.advancedSettings.customOperator.operatorIcon"
              />
            </div>
            <div className="row menu-paragraph">
              <div className="form-group dm-form-group form-inline">
                <Field
                  type="checkbox"
                  className="form-control m-0"
                  name="enable_operator_business_hours"
                  component={CheckboxField}
                />
                <LabelWithTooltip
                  htmlFor="enable_operator_business_hours"
                  name="bot.advancedSettings.customOperator.businessHours.title"
                />
              </div>
              {botEditForm.enable_operator_business_hours && this.renderOperatorBusinessHours()}
            </div>
          </div>
        )}
      </div>
    )
  }

  renderOperatorBusinessHours = () => {
    const { t } = this.context
    const { botEditForm } = this.props

    const dayOfWeeks = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    const dayOfWeekCheckboxs = lodash.map(dayOfWeeks, dayOfWeek => {
      return (
        <span key={`${dayOfWeek}`}>
          <Field
            type="checkbox"
            name={`operator_business_hours.day_of_week.${dayOfWeek}`}
            id={`operator_business_hours.day_of_week.${dayOfWeek}`}
            className="form-control m-0"
            component={CheckboxField}
          />
          <LabelWithTooltip
            htmlFor={`operator_business_hours.day_of_week.${dayOfWeek}`}
            className="dm-checkbox"
            name={`bot.advancedSettings.customOperator.businessHours.dayOfWeek.items.${dayOfWeek}`}
          />
        </span>
      )
    })

    return (
      <React.Fragment>
        <div className="form-group dm-form-group row with-indent">
          <LabelWithTooltip
            className="col-sm-3"
            name="bot.advancedSettings.customOperator.businessHours.dayOfWeek.title"
          />
          <div className="day-of-week col-sm-9">
            {dayOfWeekCheckboxs}
            <Field name="operator_business_hours.day_of_week" component={this.renderError} />
          </div>
        </div>
        <div className="form-group dm-form-group row with-indent">
          <LabelWithTooltip
            className="col-sm-4"
            name="bot.advancedSettings.customOperator.businessHours.duration"
          />
          <div className="radio-inline col-sm-2">
            <Field
              name="operator_business_hours.is_full_time"
              type="radio"
              value="true"
              component="input"
              className="form-control"
              id="full_time"
            />
            <label htmlFor="full_time" className="fulltime">
              {t('bot.advancedSettings.customOperator.businessHours.fullTime')}
            </label>
          </div>
          <div className="radio-inline col-sm-5">
            <Field
              name="operator_business_hours.is_full_time"
              type="radio"
              value="false"
              component="input"
              className="form-control"
              id="limit_hours"
            />
            <div className="between">
              <Field
                id="operator_business_hours.from"
                name="operator_business_hours.from"
                className="form-control dm-form-control"
                type="time"
                placeholder="hh:mm"
                component={TimeField}
                disabled={botEditForm.operator_business_hours.is_full_time === 'true'}
              />
              <span className="between-sign">{t('common.betweenSign')}</span>
              <Field
                id="operator_business_hours.to"
                name="operator_business_hours.to"
                className="form-control dm-form-control"
                type="time"
                placeholder="hh:mm"
                component={TimeField}
                disabled={botEditForm.operator_business_hours.is_full_time === 'true'}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderError = field => {
    const { t } = this.context
    const {
      meta: { error, touched },
    } = field
    if (!error || !touched) return null

    if (typeof error === 'object') {
      return <div className="error">{t(error.id, error.values)}</div>
    } else {
      return <div className="error">{t(error)}</div>
    }
  }

  renderClassificationFailsTopicForTopic = () => {
    const { availableTopics, botEditForm } = this.props
    const useClassificationFailsTopicForTopic = !!botEditForm.use_classification_fails_topic_for_topic
    return (
      <div>
        <div className="form-group form-inline checkbox dm-form-group">
          <Field
            type="checkbox"
            className="form-control m-0"
            name="use_classification_fails_topic_for_topic"
            component={CheckboxField}
          />
          <LabelWithTooltip
            htmlFor="use_classification_fails_topic_for_topic"
            name="bot.advancedSettings.classificationFailsTopicForTopic.firstText"
          />
          <Field
            type="number"
            name="classification_fails_count_for_topic"
            className="form-control dm-form-control text-right ml-3"
            component={InputField}
            disabled={!useClassificationFailsTopicForTopic}
            min="1"
            max="3"
          />
          <LabelWithTooltip
            htmlFor="use_classification_fails_topic_for_topic"
            name="bot.advancedSettings.classificationFailsTopicForTopic.secondText"
          />
          <Field
            name="classification_fails_topic_for_topic_id"
            items={availableTopics}
            valueKey="id"
            displayKey="name"
            className="form-control dm-form-control ml-3"
            component={SelectField}
            disabled={!useClassificationFailsTopicForTopic}
            empty={true}
            parse={value => value && parseInt(value, 10)}
            order="asc"
          />
          <LabelWithTooltip
            htmlFor="use_classification_fails_topic_for_topic"
            name="bot.advancedSettings.classificationFailsTopicForTopic.thirdText"
          />
        </div>
        <div className="with-indent">
          <Field name="option_of_classification_fails_topic_for_topic" component={this.renderError} />
        </div>
      </div>
    )
  }

  renderClassificationFailsTopicForFaq = () => {
    const { availableTopics, botEditForm } = this.props
    const useClassificationFailsTopicForFaq = !!botEditForm.use_classification_fails_topic_for_faq
    return (
      <div>
        <div className="form-group form-inline checkbox dm-form-group">
          <Field
            type="checkbox"
            className="form-control m-0"
            name="use_classification_fails_topic_for_faq"
            component={CheckboxField}
          />
          <LabelWithTooltip
            htmlFor="use_classification_fails_topic_for_faq"
            name="bot.advancedSettings.classificationFailsTopicForFaq.firstText"
          />
          <Field
            type="number"
            name="classification_fails_count_for_faq"
            className="form-control dm-form-control text-right ml-3"
            component={InputField}
            disabled={!useClassificationFailsTopicForFaq}
            min="1"
            max="3"
          />
          <LabelWithTooltip
            htmlFor="use_classification_fails_topic_for_faq"
            name="bot.advancedSettings.classificationFailsTopicForFaq.secondText"
          />
          <Field
            name="classification_fails_topic_for_faq_id"
            items={availableTopics}
            valueKey="id"
            displayKey="name"
            className="form-control dm-form-control ml-3"
            component={SelectField}
            disabled={!useClassificationFailsTopicForFaq}
            empty={true}
            parse={value => value && parseInt(value, 10)}
            order="asc"
          />
          <LabelWithTooltip
            htmlFor="use_classification_fails_topic_for_faq"
            name="bot.advancedSettings.classificationFailsTopicForFaq.thirdText"
          />
        </div>
        <div className="with-indent">
          <Field name="option_of_classification_fails_topic_for_faq" component={this.renderError} />
        </div>
      </div>
    )
  }

  renderUseFulltextSearch = () => {
    const { botEditForm } = this.props

    return (
      <div>
        <div className="form-group form-inline checkbox">
          <Field
            type="checkbox"
            className="form-control m-0"
            name="use_fulltext_search"
            component={CheckboxField}
          />
          <LabelWithTooltip
            htmlFor="use_fulltext_search"
            name="bot.advancedSettings.useFulltextSearch.title"
          />
        </div>
        {botEditForm.use_fulltext_search && (
          <div className="form-group form-inline checkbox with-indent">
            <Field
              type="checkbox"
              className="form-control m-0"
              name="use_strict_matching"
              component={CheckboxField}
            />
            <LabelWithTooltip
              htmlFor="use_strict_matching"
              name="bot.advancedSettings.useFulltextSearch.useStrictMatching"
            />
          </div>
        )}
      </div>
    )
  }

  renderUseAdvancedMachineLearning = () => {
    return (
      <div>
        <div className="form-group form-inline checkbox">
          <Field
            type="checkbox"
            className="form-control m-0"
            name="use_advanced_machine_learning"
            component={CheckboxField}
          />
          <LabelWithTooltip
            htmlFor="use_advanced_machine_learning"
            name="bot.advancedSettings.useAdvancedMachineLearning"
          />
        </div>
      </div>
    )
  }

  renderSkipEchoMessage = () => {
    return (
      <div>
        <div className="form-group form-inline checkbox">
          <Field
            type="checkbox"
            className="form-control m-0"
            name="skip_echo_for_classification"
            component={CheckboxField}
          />
          <LabelWithTooltip
            htmlFor="skip_echo_for_classification"
            name="bot.advancedSettings.skipEchoForClassification"
          />
        </div>
      </div>
    )
  }

  renderDigitMasking = () => {
    const { t } = this.context
    const { botEditForm } = this.props
    const enableDigitMasking = !!botEditForm.enable_digit_masking
    return (
      <div>
        <div className="form-group form-inline checkbox">
          <Field
            type="checkbox"
            className="form-control m-0"
            name="enable_digit_masking"
            component={CheckboxField}
          />
          <LabelWithTooltip
            htmlFor="enable_digit_masking"
            name="bot.advancedSettings.digitMasking.title"
            direction="left top"
          />
        </div>
        {enableDigitMasking && (
          <div className="menu-paragraph" key="digitMasking">
            <div className="form-group row dm-form-group">
              <LabelWithTooltip
                htmlFor="digit_masking_length"
                className="col-sm-4 vertical-centering"
                name="bot.advancedSettings.digitMasking.length"
              />
              <div className="col-sm-2">
                <Field
                  type="number"
                  name="digit_masking_length"
                  className="form-control dm-form-control text-right"
                  component={InputField}
                  min="4"
                  max="20"
                />
              </div>
              <label className="col-sm-6 vertical-centering">
                {t('bot.advancedSettings.digitMasking.unit')}
              </label>
            </div>
            <ul className="notes">
              <li>{t('bot.advancedSettings.digitMasking.notes.length')}</li>
            </ul>
          </div>
        )}
      </div>
    )
  }

  renderPatterns = ({ fields }) => {
    const { t } = this.context

    const columns = [
      {
        Cell: (name, index) => (
          <Field
            name={`${name}.pattern`}
            component={InputField}
            type="text"
            className="form-control dm-form-control"
            placeholder={index === 0 ? t('topic.patterns.placeholder') : ''}
            maxLength="280"
            disabled={(fields.get(index) || {}).is_preset}
          />
        ),
      },
    ]

    return (
      <div className="dm-interruption-patterns">
        <IncrementableTable
          withHeader={false}
          fields={fields}
          columns={columns}
          deletable={field => !field.is_preset}
          maxRows={20}
        />
      </div>
    )
  }

  renderUseResetFeature = () => {
    const { t } = this.context
    const { botEditForm } = this.props

    return (
      <div>
        <div className="form-group form-inline checkbox">
          <Field type="checkbox" className="form-control m-0" name="use_reset" component={CheckboxField} />
          <LabelWithTooltip
            htmlFor="use_reset"
            name="bot.advancedSettings.reset.title"
            direction="right top"
          />
        </div>
        {botEditForm.use_reset && (
          <div className="ml-5">
            <LabelWithTooltip
              className="dm-title-mini"
              name="bot.advancedSettings.reset.patterns"
              direction="right top"
            />
            <FieldArray name="reset_patterns" component={this.renderPatterns} />
            <div className="form-group checkbox">
              <Field
                type="checkbox"
                className="form-control m-0"
                name="show_reset_in_balloon"
                component={CheckboxField}
              />
              <LabelWithTooltip
                htmlFor="show_reset_in_balloon"
                className="dm-title-mini"
                name="bot.advancedSettings.reset.showInBalloon"
                direction="right top"
              />
            </div>
            {botEditForm.show_reset_in_balloon && (
              <div className="form-group row dm-form-group menu-paragraph">
                <LabelWithTooltip
                  htmlFor="reset_label_in_balloon"
                  className="col-sm-3"
                  name="bot.advancedSettings.reset.labelInBalloon.title"
                />
                <div className="col-sm-5">
                  <Field
                    name="reset_label_in_balloon"
                    type="text"
                    className="form-control dm-form-control"
                    maxLength="16"
                    component={InputField}
                    placeholder={t('bot.advancedSettings.reset.labelInBalloon.default')}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  renderUseUndoFeature = () => {
    const { t } = this.context
    const { botEditForm } = this.props

    return (
      <div>
        <div className="form-group form-inline checkbox">
          <Field type="checkbox" className="form-control m-0" name="use_undo" component={CheckboxField} />
          <LabelWithTooltip htmlFor="use_undo" name="bot.advancedSettings.undo.title" direction="right top" />
        </div>
        {botEditForm.use_undo && (
          <div className="ml-5">
            <LabelWithTooltip
              className="dm-title-mini"
              name="bot.advancedSettings.undo.patterns"
              direction="right top"
            />
            <FieldArray name="undo_patterns" component={this.renderPatterns} />
            <div className="form-group checkbox">
              <Field
                type="checkbox"
                className="form-control m-0"
                name="show_undo_in_balloon"
                component={CheckboxField}
              />
              <LabelWithTooltip
                htmlFor="show_undo_in_balloon"
                className="dm-title-mini"
                name="bot.advancedSettings.undo.showInBalloon"
                direction="right top"
              />
            </div>
            {botEditForm.show_undo_in_balloon && (
              <div className="form-group row dm-form-group menu-paragraph">
                <LabelWithTooltip
                  htmlFor="undo_label_in_balloon"
                  className="col-sm-3"
                  name="bot.advancedSettings.undo.labelInBalloon.title"
                />
                <div className="col-sm-5">
                  <Field
                    name="undo_label_in_balloon"
                    type="text"
                    className="form-control dm-form-control"
                    maxLength="16"
                    component={InputField}
                    placeholder={t('bot.advancedSettings.undo.labelInBalloon.default')}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  renderLanguageSetting = () => {
    const { t } = this.context
    const languages = [
      { code: 'ja_JP', label: t('bot.advancedSettings.language.ja_JP') },
      { code: 'en_US', label: t('bot.advancedSettings.language.en_US') },
      { code: 'zh_CN', label: t('bot.advancedSettings.language.zh_CN') },
    ]

    return (
      <div>
        <div className="dm-form-group form-inline mt-3">
          <LabelWithTooltip
            htmlFor="language"
            name="bot.advancedSettings.language.title"
            direction="right top"
          />
          <Field
            name="language"
            items={languages}
            valueKey="code"
            displayKey="label"
            className="form-control dm-form-control ml-3"
            component={SelectField}
            empty={false}
          />
        </div>
      </div>
    )
  }
}

export class InterruptionList extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    bot_id: PropTypes.number.isRequired,
    interruptions: PropTypes.array.isRequired,
  }

  render() {
    const { t } = this.context
    const { interruptions, bot_id } = this.props
    return (
      <div>
        <table className="table table-striped table-bordered dm-table hover">
          <thead>
            <tr>
              <th>{t('bot.keyword')}</th>
            </tr>
          </thead>
          <tbody>
            {interruptions.map((interruption, index) => (
              <InterruptionItem key={index} interruption={interruption} />
            ))}
          </tbody>
        </table>
        <div className="text-right">
          <Link className="btn btn-primary dm-btn" to={'/interruptions/new'} query={{ botId: bot_id }}>
            {t('common.new')}
          </Link>
        </div>
      </div>
    )
  }
}

export class InterruptionItem extends Component {
  static propTypes = {
    interruption: PropTypes.object.isRequired,
  }

  render() {
    const { interruption } = this.props
    return (
      <tr>
        <td>
          <Link to={`/interruptions/${interruption.id}`}>{interruption.keyword}</Link>
        </td>
      </tr>
    )
  }
}

export default BotEditComponent
