import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Collapse from 'react-collapse'
import Collapsible from 'react-collapsible'
import { Field, Fields } from 'redux-form'
import Sticky from 'react-sticky-state'

import { CheckboxField, InputField, AutoCompleteField } from '../common/fields/FormFields'
import Tooltip from '../common/Tooltip'
import LabelWithTooltip from '../common/LabelWithTooltip'
import ScenarioMatchingTab from './ScenarioMatchingTab'
import ActionList from './ActionList'
import ActionSubList from './ActionSubList'
import Trainer from '../../containers/bot/Trainer'

import { filterSystemVariables, filterUpperVariables } from '../../helpers/replaceSystemVariable'
import { isPermitted } from '../../helpers/permission'

export class TopicEditComponent extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    bot: PropTypes.object.isRequired,
    topic: PropTypes.object,
    entities: PropTypes.array.isRequired,
    actions: PropTypes.array,
    integrations: PropTypes.array.isRequired,
    domains: PropTypes.array.isRequired,
    topics: PropTypes.array.isRequired,
    faqs: PropTypes.array.isRequired,
    faqFiles: PropTypes.array.isRequired,
    tasks: PropTypes.array.isRequired,
    handleDomainValueChanged: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    isLoadedImage: PropTypes.bool,
    onChangeImageMethod: PropTypes.func.isRequired,
    onChangeImageFile: PropTypes.func.isRequired,
    onClickImageReset: PropTypes.func.isRequired,
    onChangeChooseScenarioImageFile: PropTypes.func.isRequired,
    onChangeCarouselImageFile: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    variables: PropTypes.array.isRequired,
    defaultOpenedScenarioMatching: PropTypes.bool,
    onChangeSlotDefinition: PropTypes.func.isRequired,
    onChangeImageActionType: PropTypes.func.isRequired,
    onChangeConditionType: PropTypes.func.isRequired,
    condition_groups: PropTypes.array,
    enableFeedback: PropTypes.bool,
    root_domain: PropTypes.object,
    domain1: PropTypes.object,
    domain2: PropTypes.object,
    domain3: PropTypes.object,
    domain4: PropTypes.object,
    currentDomains: PropTypes.array,
    isDraft: PropTypes.bool,
  }

  static defaultProps = {
    currentDomains: [],
    actions: [],
  }

  constructor() {
    super()
    this.state = { isOpened: false }
  }

  onSubmit = _e => {
    document.activeElement.blur()
  }

  toggleDomains = () => {
    this.setState({ isOpened: !this.state.isOpened })
  }

  render() {
    const { t } = this.context
    const { isOpened } = this.state
    const {
      bot,
      entities,
      actions,
      integrations,
      domains,
      topic,
      topics,
      faqs,
      faqFiles,
      tasks,
      variables,
      handleDomainValueChanged,
      handleSubmit,
      handleSave,
      handleDelete,
      isSubmitting,
      defaultOpenedScenarioMatching,
      onChangeSlotDefinition,
      onChangeImageActionType,
      onChangeConditionType,
      isLoadedImage,
      onChangeImageMethod,
      onChangeImageFile,
      onClickImageReset,
      onChangeChooseScenarioImageFile,
      onChangeCarouselImageFile,
      onError,
      condition_groups,
      enableFeedback,
      root_domain,
      domain1,
      domain2,
      domain3,
      domain4,
      currentDomains,
      isDraft,
    } = this.props

    const systemVariables = filterSystemVariables()
    const chatbotConstants = lodash.map(bot.constant_definitions, 'name')
    const userVariables = filterUpperVariables(lodash.map(bot.memory_definitions, 'name'), [
      ...systemVariables,
    ])
    const scenarioVariables = filterUpperVariables(lodash.map(variables, 'variable'), [
      ...systemVariables,
      ...userVariables,
    ])
    const conditionVariables = lodash.map(condition_groups, 'name')

    const restrictedActions = ['abort_task', 'complete_task']
    const hierarchicalDomain =
      lodash.dropRightWhile(lodash.map(currentDomains, 'name'), lodash.isEmpty).join(' > ') ||
      t('topic.unspecified')

    return (
      <div>
        <div className="row dm-topics">
          <form className="text-left col-md-9" onSubmit={handleSave}>
            {isDraft && (
              <div className="form-group">
                <span className="label label-draft">{t('common.draft')}</span>
              </div>
            )}
            <div className="form-group">
              <LabelWithTooltip htmlFor="name" className="dm-title" name="topic.name" />
              <Field
                name="name"
                component={InputField}
                type="text"
                className="form-control dm-form-control"
                maxLength="255"
              />
            </div>

            {/* Feedback */}
            {bot.use_feedback && isPermitted('feature_feedback', this.context) && (
              <div className="form-group form-inline">
                <Field type="checkbox" name="enable_feedback" component={CheckboxField} />
                <LabelWithTooltip
                  htmlFor="enable_feedback"
                  className="dm-checkbox form-control-static"
                  name="topic.enableFeedback"
                />
              </div>
            )}

            {/* Examples / Patterns */}
            <div className="form-group">
              <Fields
                names={['examples', 'patterns']}
                component={ScenarioMatchingTab}
                defaultOpened={defaultOpenedScenarioMatching}
              />
            </div>

            {/* Domains */}
            <div className={isOpened ? 'form-group is-open' : 'form-group'}>
              <span className="toggleDomains" onClick={() => this.toggleDomains()}>
                <label className="dm-title">{t('topic.domains')}</label>
                <span>(</span>
                <span className="hierarchical-domains">{hierarchicalDomain}</span>
                <span>)</span>
                <Tooltip name="topic.tooltip.domains" />
                <span type="button" className="btn btn-link glyphicon glyphicon-collapse-down" />
              </span>
              <Collapse isOpened={isOpened}>
                <div className="form-group dm-form-group row">
                  <LabelWithTooltip
                    htmlFor="domains[0].name"
                    className="dm-label indent form-inline col-sm-2"
                    name="topic.domain1"
                  />
                  <div className="col-sm-10">
                    <Field
                      type="text"
                      name="domains[0].name"
                      className="form-control dm-form-control"
                      maxLength="40"
                      component={AutoCompleteField}
                      items={root_domain ? root_domain.domains : []}
                      displayKey="name"
                      valueKey="name"
                      onChange={(event, newValue, previousValue) =>
                        handleDomainValueChanged('domain1', newValue, previousValue)
                      }
                    />
                  </div>
                </div>
                <div className="form-group dm-form-group row">
                  <LabelWithTooltip
                    htmlFor="domains[1].name"
                    className="dm-label indent form-inline col-sm-2"
                    name="topic.domain2"
                  />
                  <div className="col-sm-10">
                    <Field
                      type="text"
                      name="domains[1].name"
                      className="form-control dm-form-control"
                      maxLength="40"
                      component={AutoCompleteField}
                      items={domain1 ? domain1.domains : []}
                      displayKey="name"
                      valueKey="name"
                      onChange={(event, newValue, previousValue) =>
                        handleDomainValueChanged('domain2', newValue, previousValue)
                      }
                    />
                  </div>
                </div>
                <div className="form-group dm-form-group row">
                  <LabelWithTooltip
                    htmlFor="domains[2].name"
                    className="dm-label indent form-inline col-sm-2"
                    name="topic.domain3"
                  />
                  <div className="col-sm-10">
                    <Field
                      type="text"
                      name="domains[2].name"
                      className="form-control dm-form-control"
                      maxLength="40"
                      component={AutoCompleteField}
                      items={domain2 ? domain2.domains : []}
                      displayKey="name"
                      valueKey="name"
                      onChange={(event, newValue, previousValue) =>
                        handleDomainValueChanged('domain3', newValue, previousValue)
                      }
                    />
                  </div>
                </div>
                <div className="form-group dm-form-group row">
                  <LabelWithTooltip
                    htmlFor="domains[3].name"
                    className="dm-label indent form-inline col-sm-2"
                    name="topic.domain4"
                  />
                  <div className="col-sm-10">
                    <Field
                      type="text"
                      name="domains[3].name"
                      className="form-control dm-form-control"
                      maxLength="40"
                      component={AutoCompleteField}
                      items={domain3 ? domain3.domains : []}
                      displayKey="name"
                      valueKey="name"
                      onChange={(event, newValue, previousValue) =>
                        handleDomainValueChanged('domain4', newValue, previousValue)
                      }
                    />
                  </div>
                </div>
                <div className="form-group dm-form-group row">
                  <LabelWithTooltip
                    htmlFor="domains[4].name"
                    className="dm-label indent form-inline col-sm-2"
                    name="topic.domain5"
                  />
                  <div className="col-sm-10">
                    <Field
                      type="text"
                      name="domains[4].name"
                      className="form-control dm-form-control"
                      maxLength="40"
                      component={AutoCompleteField}
                      items={domain4 ? domain4.domains : []}
                      displayKey="name"
                      valueKey="name"
                    />
                  </div>
                </div>
              </Collapse>
            </div>

            {/* Actions */}
            <div className="form-group">
              <label className="dm-title">
                {t('topic.actions')}
                <Tooltip name="topic.tooltip.actionsCenter" />
              </label>
              <ActionList
                actions={actions}
                integrations={integrations}
                entities={entities}
                domains={domains}
                topics={topics}
                faqs={faqs}
                faqFiles={faqFiles}
                tasks={tasks}
                isLoadedImage={isLoadedImage}
                onChangeImageMethod={onChangeImageMethod}
                onChangeImageFile={onChangeImageFile}
                onClickImageReset={onClickImageReset}
                onChangeChooseScenarioImageFile={onChangeChooseScenarioImageFile}
                onChangeCarouselImageFile={onChangeCarouselImageFile}
                onError={onError}
                userVariables={userVariables}
                chatbotConstants={chatbotConstants}
                variables={variables}
                onChangeSlotDefinition={onChangeSlotDefinition}
                onChangeImageActionType={onChangeImageActionType}
                onChangeConditionType={onChangeConditionType}
                restrictedActions={restrictedActions}
                condition_groups={condition_groups}
                enableFeedback={enableFeedback}
              />
            </div>

            {/* Sticky footer */}
            <Sticky>
              <div className="sticky bottom">
                <div className="dm-sticky-buttons">
                  <span className="draft-form">
                    <Field
                      type="checkbox"
                      name="is_draft"
                      disabled={isSubmitting}
                      component={CheckboxField}
                    />
                    <LabelWithTooltip
                      htmlFor="is_draft"
                      name="topic.saveAsDraft"
                      className="dm-checkbox form-control-static"
                      direction="right top"
                    />
                  </span>
                  <button
                    type="submit"
                    className="btn btn-primary dm-btn"
                    disabled={isSubmitting}
                    onClick={this.onSubmit}
                  >
                    {t('common.save')}
                  </button>
                  <Trainer
                    bot={bot}
                    handleSubmit={handleSubmit}
                    handleSave={handleSave}
                    isSubmitting={isSubmitting}
                  />
                  {topic.id && (
                    <button
                      type="button"
                      className="btn btn-danger dm-btn"
                      onClick={handleDelete}
                      disabled={isSubmitting}
                    >
                      {t('common.delete')}
                    </button>
                  )}
                </div>
              </div>
            </Sticky>
          </form>
          <div className="hidden-sm-down col-md-3">
            <div className="dm-topic-sidebar">
              <ActionSubList
                actions={actions}
                integrations={integrations}
                topics={topics}
                condition_groups={condition_groups}
              />
              <Collapsible
                trigger={<LabelWithTooltip name="topic.variables.title" direction="left" />}
                transitionTime={300}
                triggerTagName="h4"
                open={true}
                overflowWhenOpen="visible"
              >
                <ul className="nav nav-pills nav-stacked variables">
                  {scenarioVariables.length > 0 && [
                    <li key="header" className="header">
                      {t('topic.variables.scenario')}
                    </li>,
                    <li key="body" className="body">
                      <ul>
                        {scenarioVariables.map((variable, index) => (
                          <li key={index}>{variable}</li>
                        ))}
                      </ul>
                    </li>,
                  ]}
                  {userVariables.length > 0 && [
                    <li key="header" className="header">
                      {t('topic.variables.user')}
                    </li>,
                    <li key="body" className="body">
                      <ul>
                        {userVariables.map((variable, index) => (
                          <li key={index}>{variable}</li>
                        ))}
                      </ul>
                    </li>,
                  ]}
                  {systemVariables.length > 0 && [
                    <li key="header" className="header">
                      {t('topic.variables.system')}
                    </li>,
                    <li key="body" className="body">
                      <ul>
                        {systemVariables.map((variable, index) => (
                          <li key={index}>{t('systemVariables.' + variable)}</li>
                        ))}
                      </ul>
                    </li>,
                  ]}
                  {chatbotConstants.length > 0 && [
                    <li key="header" className="header">
                      {t('topic.variables.chatbotConstant')}
                    </li>,
                    <li key="body" className="body">
                      <ul>
                        {chatbotConstants.map((constant, index) => (
                          <li key={index}>{constant}</li>
                        ))}
                      </ul>
                    </li>,
                  ]}
                  {conditionVariables.length > 0 && [
                    <li key="header" className="header">
                      {t('condition_group.title')}
                    </li>,
                    <li key="body" className="body">
                      <ul>
                        {conditionVariables.map((variable, index) => (
                          <li key={index}>{variable}</li>
                        ))}
                      </ul>
                    </li>,
                  ]}
                </ul>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TopicEditComponent
