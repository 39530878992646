import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Tooltip from '../common/Tooltip'
import { isPermitted } from '../../helpers/permission'

export class AddNewAction extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    onAdd: PropTypes.func,
    keepActive: PropTypes.bool,
    restrictedActions: PropTypes.array,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    keepActive: false,
    restrictedActions: [],
  }

  constructor() {
    super()
    this.state = { type: 'speak', isActive: false }
  }

  changeType = e => {
    this.setState({ type: e.target.value })
  }

  addNewAction = () => {
    this.props.onAdd(this.state.type)
  }

  onFocus = () => {
    this.setState({ isActive: true })

    //  Reset automatic scroll by browser to keep original position
    this.container.scrollTop = 0
  }

  onBlur = () => {
    this.setState({ isActive: false })
  }

  render() {
    const { t } = this.context
    const { restrictedActions, disabled } = this.props
    const isActive = this.state.isActive || this.props.keepActive

    const actions = [
      'speak',
      'upload_image',
      'ai_assistant',
      '----',
      'slot',
      'confirm',
      'choose',
      'carousel',
      'form',
      '----',
      'operator',
      'exit_topic',
      'rerun_scenario',
      'change_scenario',
      'choose_scenario',
      '----',
      'integration',
      'item_list',
      'choose_list',
      'carousel_list',
      '----',
      'faq',
      'generate_answer',
      '----',
      'register_task',
      'abort_task',
      'complete_task',
      '----',
      'variable',
      'log',
    ]

    //  Remove restricted actions
    lodash.pullAll(actions, restrictedActions)

    //  Check permission
    if (!isPermitted('feature_operator', this.context)) {
      lodash.pull(actions, 'operator')
    }
    if (!isPermitted('feature_integration', this.context)) {
      lodash.pullAll(actions, ['integration', 'item_list', 'choose_list', 'carousel_list'])
    }
    if (!isPermitted('feature_faq_file', this.context)) {
      lodash.pull(actions, 'faq')
    }
    if (!isPermitted('feature_task', this.context)) {
      lodash.pullAll(actions, ['register_task', 'complete_task'])
    }
    if (!isPermitted('feature_variable_operation', this.context)) {
      lodash.pullAll(actions, ['variable'])
    }
    if (!isPermitted('feature_output_log', this.context)) {
      lodash.pullAll(actions, ['log'])
    }

    //  Remove consecutive or trailing separators
    const filteredActions = lodash
      .chain(actions)
      .reject((action, i) => i > 0 && actions[i - 1] === action)
      .dropRightWhile(action => action === '----')
      .value()

    const options = filteredActions.map((action, index) => {
      if (action === '----') {
        return (
          <option key={`hr${index}`} className="hr" disabled={true}>
            {t('topic.actionList.hr')}
          </option>
        )
      } else {
        return (
          <option key={action} value={action}>
            {t(`topic.actionList.${action}.title`)}
          </option>
        )
      }
    })

    return (
      <div
        className={`panel-connector dm-panel-connector add ${isActive ? 'is-active' : ''}`}
        ref={container => (this.container = container)}
      >
        <div className="dm-panel-add-actions">
          <select
            name="type"
            className="form-control"
            onChange={this.changeType}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          >
            {options}
          </select>
          <button
            type="button"
            className="btn btn-primary dm-btn"
            onClick={this.addNewAction}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            disabled={disabled}
            title={disabled ? t('topic.actionList.maxLengthMessage') : undefined}
          >
            {t('topic.actionList.add')}
          </button>
          <Tooltip name="topic.tooltip.actionList.add" />
        </div>
      </div>
    )
  }
}

export default AddNewAction
