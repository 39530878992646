import { deleteCache } from './api'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchFaqFiles = (token, filter = {}) => dispatch => {
  return request.get('/faq_files/', {
    token,
    dispatch,
    body: filter,
    target: 'faq_files',
    schema: Schemas.faqFiles,
  })
}

export const fetchFaqFile = (token, id) => dispatch => {
  return request.get(`/faq_files/${id}`, {
    token,
    dispatch,
    target: 'faq_files',
    schema: Schemas.faqFile,
  })
}

export const updateFaqFile = (token, body, id) => dispatch => {
  return request.put(`/faq_files/${id}`, {
    token,
    dispatch,
    body,
    target: 'faq_files',
    schema: Schemas.faqFile,
  })
}

export const deleteFaqFile = (token, id) => dispatch => {
  return request
    .delete(`/faq_files/${id}`, {
      token,
      dispatch,
      target: 'faq_files',
    })
    .then(() => dispatch(deleteCache('faq_files', id)))
}

export const importFaqFile = (token, body) => dispatch => {
  return request.post('/faq_files/import', {
    token,
    dispatch,
    body,
    target: 'faq_files',
    schema: Schemas.faqFile,
  })
}

export const deleteFaqFileRevision = (token, faq_file_id, id) => dispatch => {
  return request.delete(`/faq_files/${faq_file_id}/revisions/${id}`, {
    token,
    dispatch,
    target: 'faq_files',
  })
}
