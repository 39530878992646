import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { SketchPicker } from 'react-color'
import queryString from 'query-string'

import { WebChatPreview } from './WebChatPreview'
import {
  InputField,
  SelectField,
  CheckboxField,
  RangeField,
  FileField,
} from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'

const basePresetColors = [
  '#D0021B',
  '#F5A623',
  '#F8E71C',
  '#8B572A',
  '#7ED321',
  '#417505',
  '#BD10E0',
  '#9013FE',
  '#4A90E2',
  '#50E3C2',
  '#B8E986',
  '#000000',
  '#4A4A4A',
  '#9B9B9B',
  '#FFFFFF',
]

const customItems = [
  {
    key: 'header',
    name: 'application.web.customize.colors.header',
    buttons: ['bg', 'font'],
    additionalPresetColors: {
      bg: ['#144061', '#00665a', '#cc1919', '#f08c00'],
      font: ['#ffffff'],
    },
  },
  {
    key: 'background',
    name: 'application.web.customize.colors.background',
    buttons: ['bg', 'font'],
    additionalPresetColors: {
      bg: ['#e9eff5', '#e6f5ef', '#ffe6e6', '#fff6e6'],
      font: ['#9e9e9e'],
    },
  },
  {
    key: 'botBalloon',
    name: 'application.web.customize.colors.botBalloon',
    buttons: ['bg', 'font'],
    additionalPresetColors: {
      bg: ['#caeaff', '#b8e6e0', '#ffcdd2', '#ffecb3'],
      font: ['#141414'],
    },
  },
  {
    key: 'userBalloon',
    name: 'application.web.customize.colors.userBalloon',
    buttons: ['bg', 'font'],
    additionalPresetColors: {
      bg: ['#ffffff'],
      font: ['#141414'],
    },
  },
  {
    key: 'sendButton',
    name: 'application.web.customize.colors.sendButton',
    buttons: ['bg', 'font'],
    additionalPresetColors: {
      bg: ['#1d5c8b', '#009987', '#e63232', '#f08c00'],
      font: ['#ffffff'],
    },
  },
]

export class EmbeddedTagEdit extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    application: PropTypes.object.isRequired,
    inPublishing: PropTypes.bool.isRequired,
    isPublished: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    formValues: PropTypes.object.isRequired,
    handlePublishLauncherImage: PropTypes.func.isRequired,
    handleCopy: PropTypes.func.isRequired,
    restoreDefaultColor: PropTypes.func.isRequired,
    changeLauncherImageFile: PropTypes.func.isRequired,
    launcherImageState: PropTypes.object.isRequired,
    isDirtyCustomize: PropTypes.bool.isRequired,
  }

  constructor() {
    super()
    this.state = {
      displayColorPicker: {},
      isOpenLauncher: true,
      isOpenChat: false,
    }
  }

  toggleDisplayColorPicker = place => {
    const { displayColorPicker } = this.state
    this.setState({ displayColorPicker: { [place]: !displayColorPicker[place] } })
  }

  toggleIsOpen = event => {
    this.setState({ isOpenLauncher: !this.state.isOpenLauncher })
    this.setState({ isOpenChat: !this.state.isOpenChat })
    event.preventDefault()
  }

  openLauncher = () => {
    this.setState({ isOpenLauncher: true })
    this.setState({ isOpenChat: false })
  }

  isLauncherInputCompleted = () => {
    const { application, formValues, launcherImageState } = this.props
    if (lodash.includes(['title-header', 'balloon-button', 'none'], formValues.embedded_launcher_type)) {
      return true
    }

    if (formValues.embedded_launcher_type === 'custom-image') {
      return (
        !launcherImageState.isInvalidImage && (launcherImageState.image_src || application.launcher_image_url)
      )
    }
  }

  renderLauncherImageField = () => {
    const { application, formValues, changeLauncherImageFile, launcherImageState } = this.props
    const { t } = this.context

    return (
      <div className="filefield">
        <Field
          type="file"
          name="launcher_image_file"
          className="form-control dm-form-control"
          component={FileField}
          onChange={changeLauncherImageFile}
        />
        {!formValues.launcher_image_file && !application.launcher_image_url && (
          <div className="error">{t('validate.required')}</div>
        )}
        {launcherImageState.isInvalidImage && <div className="error">{t('validate.invalidImage')}</div>}
        <ul>
          <li>{t('application.web.authorizedFileFormat')}</li>
          <li>{t('application.web.minimumMaximumImageSize')}</li>
          <li>{t('application.web.maxFileSize')}</li>
        </ul>
      </div>
    )
  }

  colorPickerField = field => (
    <SketchPicker
      color={field.color}
      onChangeComplete={color => field.input.onChange(color.hex)}
      presetColors={field.presetColors}
    />
  )

  colorPicker = (key, target, additionalPresetColor) => {
    const presetColors = lodash.uniq(additionalPresetColor.concat(basePresetColors))

    return (
      <div className="dm-color-picker-popover">
        <div className="dm-color-picker-cover" onClick={_ => this.toggleDisplayColorPicker(key + target)} />
        <Field
          name={`colors.${key}.${target}`}
          presetColors={presetColors}
          className="form-control dm-form-control"
          component={this.colorPickerField}
          color={this.props.formValues.colors[key][target]}
        />
      </div>
    )
  }

  renderColorPickerField = item => {
    const { formValues } = this.props
    const { t } = this.context
    const { key, name, buttons, additionalPresetColors } = item

    return (
      <div className="custom-item" key={key}>
        <div className="dm-title-mini">
          <label>{t(name)}</label>
        </div>
        <div className="dm-color-buttons">
          {buttons.indexOf('bg') >= 0 && (
            <div className="color-button">
              <button
                type="button"
                className="btn bg-button"
                onClick={_ => this.toggleDisplayColorPicker(key + 'bg')}
                style={{ backgroundColor: formValues.colors[key].bg }}
              />
              {this.state.displayColorPicker[key + 'bg']
                ? this.colorPicker(key, 'bg', additionalPresetColors['bg'])
                : null}
            </div>
          )}
          {buttons.indexOf('font') >= 0 && (
            <div className="color-button">
              <button
                type="button"
                className="btn font-button"
                onClick={_ => this.toggleDisplayColorPicker(item.key + 'font')}
              >
                <span className="letter">A</span>
                <div className="color-bar" style={{ backgroundColor: formValues.colors[key].font }} />
              </button>
              {this.state.displayColorPicker[key + 'font']
                ? this.colorPicker(key, 'font', additionalPresetColors['font'])
                : null}
            </div>
          )}
        </div>
      </div>
    )
  }

  renderOperationCheckURL = application => {
    const { t } = this.context
    const { isPublished } = this.props

    if (!isPublished) {
      return (
        <div>
          <p className="black">{t('application.isNotPublishedMessage')}</p>
        </div>
      )
    }

    const options = {
      token: application.token,
      theme: application.embedded_theme,
      fontSize: application.embedded_font_size,
      autoHideScrollbar: application.auto_hide_scrollbar,
      isImmediate: !application.embedded_pause,
    }
    if (application.embedded_theme === 'custom') {
      const colors = JSON.parse(application.colors)
      options.background_bg = colors.background.bg
      options.background_font = colors.background.font
      options.botBalloon_bg = colors.botBalloon.bg
      options.botBalloon_font = colors.botBalloon.font
      options.userBalloon_bg = colors.userBalloon.bg
      options.userBalloon_font = colors.userBalloon.font
      options.sendButton_bg = colors.sendButton.bg
      options.sendButton_font = colors.sendButton.font
    }

    const location = window.location
    const url = `${location.protocol}//${location.host}/embedded?${queryString.stringify(options)}`

    return (
      <div>
        <a className="url" href={url} target="_blank" rel="noopener noreferrer">
          {url}
          <i className="fas fa-external-link-alt" />
        </a>
      </div>
    )
  }

  generateEmbededScript = (application, formValues) => {
    const { t } = this.context
    const { inPublishing, isPublished, isDirtyCustomize } = this.props
    const location = window.location
    const tags = []
    const colors = JSON.stringify(formValues.colors)
    const isInputCompleted = !!(
      formValues.embedded_title &&
      formValues.embedded_position &&
      this.isLauncherInputCompleted() &&
      formValues.embedded_theme
    )
    if (inPublishing) {
      tags.push(t('application.inPublishingMessage'))
    } else if (!isPublished) {
      tags.push(t('application.isNotPublishedMessage'))
    } else if (!isInputCompleted) {
      tags.push(t('application.incompleteInput'))
    } else if (isDirtyCustomize) {
      tags.push(t('application.web.customize.needSave'))
    } else {
      if (formValues.embedded_position === 'position-frame') {
        const iframeUrl = `${location.protocol}//${location.host}/embedded`
        const options = {
          token: application.token,
          title: formValues.name,
          theme: formValues.embedded_theme,
          fontSize: formValues.embedded_font_size,
          autoHideScrollbar: formValues.auto_hide_scrollbar,
          position: 'frame',
          isImmediate: !formValues.embedded_pause,
        }
        if (formValues.embedded_theme === 'custom') {
          options.background_bg = formValues.colors.background.bg
          options.background_font = formValues.colors.background.font
          options.botBalloon_bg = formValues.colors.botBalloon.bg
          options.botBalloon_font = formValues.colors.botBalloon.font
          options.userBalloon_bg = formValues.colors.userBalloon.bg
          options.userBalloon_font = formValues.colors.userBalloon.font
          options.sendButton_bg = formValues.colors.sendButton.bg
          options.sendButton_font = formValues.colors.sendButton.font
        }
        const url = iframeUrl + '?' + queryString.stringify(options)
        tags.push('<!-- DialogPlay embedded tag -->')
        tags.push('<iframe width=400 height=300 class="dialogplay-embedded-chat" allow="microphone"')
        tags.push(`        src="${url}">`)
        tags.push('</iframe>')
        tags.push('<!-- End DialogPlay embedded tag -->')
      } else {
        const embeddedJsUrl = `${location.protocol}//${location.host}/js/embedded.js`
        tags.push('<!-- DialogPlay chatbot script -->')
        tags.push('<script type="text/javascript" id="dialogplay-embedded-script"')
        tags.push(`        src="${embeddedJsUrl}"`)
        tags.push(`        data-token="${application.token}"`)
        tags.push(`        data-title="${formValues.embedded_title}"`)
        tags.push(`        data-position="${formValues.embedded_position}"`)
        tags.push(`        data-chat-call-button="${formValues.embedded_launcher_type}"`)
        tags.push(`        data-closer-type="${formValues.embedded_closer_type}"`)
        if (formValues.embedded_launcher_type === 'custom-image') {
          tags.push(`        data-chat-call-button-image='${application.launcher_image_url}'`)
        }
        tags.push(`        data-theme="${formValues.embedded_theme}"`)
        tags.push(`        data-font-size="${formValues.embedded_font_size}"`)
        if (formValues.auto_popup) {
          tags.push(`        data-auto-popup-seconds="${parseInt(formValues.auto_popup_seconds, 10)}"`)
        }
        if (formValues.embedded_theme === 'custom') {
          tags.push(`        data-colors='${colors}'`)
        }
        tags.push(`        data-fit-height="${formValues.fit_height}"`)
        tags.push(`        data-auto-hide-scrollbar="${formValues.auto_hide_scrollbar}"`)
        tags.push('></script>')
        tags.push('<!-- End DialogPlay chatbot script -->')
      }
    }
    return tags.join('\n')
  }

  generateSampleScript = () => {
    const { t } = this.context
    const scripts = []
    scripts.push('<!-- dialogplay.open() -->')
    scripts.push(
      `<button type="button" onClick="dialogplay.open()">${t(
        'application.web.customize.preview.open'
      )}</button>`
    )
    scripts.push('')
    scripts.push('<!-- dialogplay.close() -->')
    scripts.push(
      `<button type="button" onClick="dialogplay.close()">${t(
        'application.web.customize.preview.close'
      )}</button>`
    )
    scripts.push('')
    scripts.push('<!-- dialogplay.toggle() -->')
    scripts.push(
      `<button type="button" onClick="dialogplay.toggle()">${t(
        'application.web.customize.preview.toggle'
      )}</button>`
    )
    return scripts.join('\n')
  }

  render() {
    const { t } = this.context
    const {
      application,
      inPublishing,
      isPublished,
      submitting,
      formValues,
      handlePublishLauncherImage,
      handleCopy,
      restoreDefaultColor,
      launcherImageState,
    } = this.props
    const { isOpenLauncher, isOpenChat } = this.state
    const isInputCompleted = !!(
      formValues.embedded_title &&
      formValues.embedded_position &&
      this.isLauncherInputCompleted() &&
      formValues.embedded_theme
    )
    const launcher_image_src =
      launcherImageState.image_src ||
      application.launcher_image_url_pending ||
      application.launcher_image_url ||
      '/image/launcher_image_sample.png'
    const embeddedThemes = [
      { id: 'theme-blue', name: t('application.web.customize.themes.blue') },
      { id: 'theme-green', name: t('application.web.customize.themes.green') },
      { id: 'theme-red', name: t('application.web.customize.themes.red') },
      { id: 'theme-orange', name: t('application.web.customize.themes.orange') },
      { id: 'custom', name: t('application.web.customize.themes.custom') },
    ]
    const embeddedPositions = [
      { id: 'position-right', name: t('application.web.customize.positions.right') },
      { id: 'position-left', name: t('application.web.customize.positions.left') },
      { id: 'position-frame', name: t('application.web.customize.positions.frame') },
    ]
    const embeddedLauncherTypes = [
      { id: 'title-header', name: t('application.web.customize.launcherTypes.titleHeader') },
      { id: 'balloon-button', name: t('application.web.customize.launcherTypes.balloonButton') },
      { id: 'custom-image', name: t('application.web.customize.launcherTypes.customImage') },
      { id: 'none', name: t('application.web.customize.launcherTypes.none') },
    ]
    const embeddedCloserTypes = [
      { id: 'header', name: t('application.web.customize.closerTypes.header') },
      { id: 'button', name: t('application.web.customize.closerTypes.button') },
    ]
    const fontSizeItems = [
      { id: -3, name: '-3' },
      { id: -2, name: '-2' },
      { id: -1, name: '-1' },
      { id: 0, name: '0' },
      { id: 1, name: '+1' },
      { id: 2, name: '+2' },
      { id: 3, name: '+3' },
    ]
    const ignoreColorPickers = []
    if (formValues.embedded_position === 'position-frame') {
      ignoreColorPickers.push('header')
    }

    return (
      <div>
        <div className="form-group dm-embedded-tag-edit">
          <LabelWithTooltip
            htmlFor="embeddedTagEditTitle"
            className="dm-title"
            name="application.web.customize.title"
          />
          <div className="form-group row dm-form-group">
            <LabelWithTooltip
              htmlFor="embeddedTitle"
              className="col-sm-4"
              name="application.web.customize.embeddedTitle"
            />
            <div className="col-sm-8">
              <Field
                type="text"
                name="embedded_title"
                className="form-control dm-form-control"
                placeholder={t('application.placeholder.web.title')}
                maxLength="255"
                component={InputField}
                disabled={!isPublished}
              />
            </div>
          </div>
          <div className="form-group row dm-form-group">
            <div>
              <LabelWithTooltip
                htmlFor="embeddedPosition"
                className="col-sm-4"
                name="application.web.customize.position"
              />
              <div className="col-sm-8">
                <Field
                  name="embedded_position"
                  className="form-control dm-form-control"
                  component={SelectField}
                  items={embeddedPositions}
                  valueKey="id"
                  displayKey="name"
                  disabled={!isPublished}
                />
              </div>
            </div>
            {formValues.embedded_position === 'position-frame' && (
              <div className="form-group form-inline">
                <div className="col-sm-4" />
                <div className="col-sm-8">
                  <Field
                    type="checkbox"
                    className="form-control"
                    name="embedded_pause"
                    component={CheckboxField}
                    disabled={!application.is_cachable}
                  />
                  <LabelWithTooltip
                    htmlFor="embedded_pause"
                    className="dm-checkbox form-control-static"
                    name="application.web.customize.pause"
                  />
                  <label>{t('application.web.customize.disableCache')}</label>
                </div>
              </div>
            )}
          </div>
          {formValues.embedded_position !== 'position-frame' && (
            <div className="form-group row dm-form-group">
              <LabelWithTooltip
                htmlFor="embeddedLauncherType"
                className="col-sm-4"
                name="application.web.customize.launcherType"
              />
              <div className="col-sm-8">
                <Field
                  name="embedded_launcher_type"
                  className="form-control dm-form-control"
                  component={SelectField}
                  items={embeddedLauncherTypes}
                  valueKey="id"
                  displayKey="name"
                  disabled={!isPublished}
                  onChange={this.openLauncher}
                />
                {formValues.embedded_launcher_type === 'custom-image' && this.renderLauncherImageField()}
              </div>
            </div>
          )}
          {formValues.embedded_position !== 'position-frame' && (
            <div className="form-group ">
              <Field type="checkbox" name="auto_popup" component={CheckboxField} />
              <LabelWithTooltip
                htmlFor="auto_popup"
                className="dm-checkbox form-control-static"
                name="application.web.customize.autoPopupSeconds"
              />
              {formValues.auto_popup && (
                <div className="with-indent">
                  <div className="input-auto-popup-items">
                    <div className="input-auto-popup-seconds">
                      <Field
                        type="number"
                        name="auto_popup_seconds"
                        component={InputField}
                        max="3600"
                        min="0"
                        className="form-control text-right"
                      />
                    </div>
                    <label className="dm-title-mini">
                      {t('application.web.customize.autoPopupSecondsSuffix')}
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}
          {formValues.embedded_position !== 'position-frame' && (
            <div className="form-group row dm-form-group">
              <LabelWithTooltip
                htmlFor="embeddedCloserType"
                className="col-sm-4"
                name="application.web.customize.closerType"
              />
              <div className="col-sm-8">
                <Field
                  name="embedded_closer_type"
                  className="form-control dm-form-control"
                  component={SelectField}
                  items={embeddedCloserTypes}
                  valueKey="id"
                  displayKey="name"
                  disabled={!isPublished}
                />
              </div>
            </div>
          )}
          <div className="form-group row dm-form-group">
            <LabelWithTooltip className="col-sm-4" name="application.web.customize.fontSize" />
            <div className="col-sm-8">
              <Field
                type="range"
                name="embedded_font_size"
                component={RangeField}
                min="-3"
                max="3"
                list="fontSizes"
                items={fontSizeItems}
                valueKey="id"
                displayKey="name"
                parse={value => value && parseInt(value, 10)}
                disabled={!isPublished}
              />
            </div>
          </div>
          <div className="form-group row dm-form-group">
            <LabelWithTooltip
              htmlFor="embeddedTheme"
              className="col-sm-4"
              name="application.web.customize.theme"
            />
            <div className="col-sm-8">
              <Field
                name="embedded_theme"
                className="form-control dm-form-control"
                component={SelectField}
                items={embeddedThemes}
                valueKey="id"
                displayKey="name"
                disabled={!isPublished}
              />
            </div>
          </div>
          {formValues.embedded_position !== 'position-frame' && (
            <div className="form-group form-inline">
              <Field type="checkbox" name="fit_height" component={CheckboxField} />
              <LabelWithTooltip
                htmlFor="fit_height"
                className="dm-checkbox form-control-static"
                name="application.web.customize.fitHeight"
              />
            </div>
          )}
          <div className="form-group form-inline">
            <Field type="checkbox" name="auto_hide_scrollbar" component={CheckboxField} />
            <LabelWithTooltip
              htmlFor="auto_hide_scrollbar"
              className="dm-checkbox form-control-static"
              name="application.web.customize.autoHideScrollbar"
            />
          </div>
          <div className="form-group dm-form-group">
            {/* Web chat preview*/}
            <div className="preview-area">
              <WebChatPreview
                title={formValues.embedded_title}
                theme={formValues.embedded_theme}
                fontSize={formValues.embedded_font_size}
                colors={formValues.colors}
                icon_url={application.icon_url}
                enableFooterResetButton={(application.latest_bot || {}).enable_footer_reset_button}
                resetLabelInBalloon={(application.latest_bot || {}).reset_label_in_balloon}
                enableFooterUndoButton={(application.latest_bot || {}).enable_footer_undo_button}
                undoLabelInBalloon={(application.latest_bot || {}).undo_label_in_balloon}
                position={formValues.embedded_position}
                launcher_type={formValues.embedded_launcher_type}
                launcher_image_src={launcher_image_src}
                closer_type={formValues.embedded_closer_type}
                isOpenLauncher={isOpenLauncher}
                isOpenChat={isOpenChat}
                toggleIsOpen={this.toggleIsOpen}
                autoHideScrollbar={formValues.auto_hide_scrollbar}
              />
            </div>
            {/* Color custumize field*/}
            <div className="custom-area">
              <div className="form-group dm-form-group">
                {formValues.embedded_theme === 'custom' && (
                  <div>
                    <div className="form-group dm-form-group">
                      {lodash
                        .reject(customItems, item => ignoreColorPickers.includes(item.key))
                        .map(item => this.renderColorPickerField(item))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* color restore button, save button */}
          <div className="form-group dm-form-group text-right">
            {formValues.embedded_theme === 'custom' && (
              <button
                key="restore_color"
                type="button"
                className="btn btn-warning dm-btn"
                onClick={restoreDefaultColor}
              >
                {t('application.web.customize.colors.restore')}
              </button>
            )}
            <button
              key="save_custom"
              type="submit"
              className="btn btn-primary dm-btn"
              disabled={!isInputCompleted || submitting || inPublishing}
            >
              {t('application.web.customize.save')}
            </button>
          </div>
          {/* url for operation check */}
          <div className="form-group row dm-form-group">
            <LabelWithTooltip
              htmlFor="operationCheckUrl"
              name="application.web.customize.operationCheckUrl.title"
            />
            <p>{t('application.web.customize.operationCheckUrl.note')}</p>
            {this.renderOperationCheckURL(application)}
          </div>
          {/* embedded tag */}
          <div className="form-group row dm-form-group">
            <LabelWithTooltip htmlFor="embedded_tag" name="application.web.customize.embeddedTag" />
            <p>{t('application.web.customize.caution')}</p>
            <div className="input-group">
              <textarea
                id="embeddedTag"
                name="embeddedTag"
                className="form-control dm-form-control"
                readOnly={true}
                rows="9"
                value={this.generateEmbededScript(application, formValues)}
              />
              <label className="input-group-addon btn btn-primary dm-btn is-gray">
                {t('application.copy')}
                <button
                  type="button"
                  onClick={() => handleCopy('embeddedTag')}
                  disabled={submitting || inPublishing || !isPublished || !isInputCompleted}
                />
              </label>
            </div>
          </div>
          {/* Sample script */}
          {formValues.embedded_launcher_type === 'none' && (
            <div className="form-group row dm-form-group">
              <LabelWithTooltip htmlFor="sample_script" name="application.web.customize.sampleScript" />
              <p>{t('application.web.customize.sampleScriptNote')}</p>
              <div className="input-group">
                <textarea
                  id="sampleScript"
                  name="sampleScript"
                  className="form-control dm-form-control"
                  readOnly={true}
                  rows="9"
                  value={this.generateSampleScript()}
                />
                <label className="input-group-addon btn btn-primary dm-btn is-gray">
                  {t('application.copy')}
                  <button
                    type="button"
                    onClick={() => handleCopy('sampleScript')}
                    disabled={submitting || inPublishing || !isPublished || !isInputCompleted}
                  />
                </label>
              </div>
            </div>
          )}
          {/* publish image button */}
          {formValues.embedded_launcher_type === 'custom-image' && (
            <div className="form-group row dm-form-group">
              <LabelWithTooltip
                htmlFor="publishLauncherImage"
                name="application.web.customize.publishImage"
              />
              <p>{t('application.web.customize.cautionPublish')}</p>
              <button
                type="button"
                className="btn dm-btn btn-primary"
                onClick={handlePublishLauncherImage}
                disabled={submitting || inPublishing || !application.launcher_image_url_pending}
              >
                {t('application.web.customize.publishImage')}
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

EmbeddedTagEdit.defaultProps = {
  title: '',
  theme: 'theme-blue',
}

export default EmbeddedTagEdit
