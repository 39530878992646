const free = {
  platform_simulator: true,
  platform_web: true,
  platform_line: false,
  platform_slack: false,
  platform_line_works: false,
  platform_azure_bot_service: false,
  platform_direct_line: false,
  platform_teams: false,
  platform_skype_for_business: false,
  platform_mobilus: false,
  platform_api_v1: false,
  platform_hangouts: false,
  integration_http: false,
  integration_email: false,
  integration_google_spreadsheet: false,
  integration_excel: false,
  integration_office365: false,
  integration_salesforce: false,
  integration_salesforce_advance: false,
  integration_line_switcher: false,
  integration_okbiz: false,
  integration_tool: false,
  template_free: true,
  template_lite: false,
  template_standard: false,
  template_office365: false,
  template_salesforce: false,
  feature_faq_file: false,
  feature_carousel: true,
  feature_variable_operation: false,
  feature_integration: false,
  feature_task: false,
  feature_operator: false,
  feature_download_log: false,
  feature_import_export: true,
  feature_custom_icon: false,
  feature_feedback: false,
  feature_analytics: false,
  feature_restricted_access: false,
  feature_oauth: false,
  feature_directline_message_format: false,
  feature_line_switcher_api: false,
  feature_line_account_link: false,
  feature_line_pnp: false,
  feature_advanced_machine_learning: false,
  feature_teams_restricted_access: false,
  feature_push_api: false,
  feature_web_account_link_api: false,
  feature_translation: false,
  feature_http_integration_status_code_handing: false,
  feature_operation_history: false,
  feature_output_log: false,
  feature_faq_generator: false,
  feature_large_faq: false,
  feature_aitalk: false,
  feature_openai_default: false,
  feature_amazon_bedrock: false,
  feature_logging_url_access: false,
  payment_change_plan: true,
}

const lite = {
  platform_simulator: true,
  platform_web: true,
  platform_line: true,
  platform_slack: true,
  platform_line_works: false,
  platform_azure_bot_service: false,
  platform_direct_line: false,
  platform_teams: false,
  platform_skype_for_business: false,
  platform_mobilus: false,
  platform_api_v1: false,
  platform_hangouts: false,
  integration_http: false,
  integration_email: false,
  integration_google_spreadsheet: false,
  integration_excel: false,
  integration_office365: false,
  integration_salesforce: false,
  integration_salesforce_advance: false,
  integration_line_switcher: false,
  integration_okbiz: false,
  integration_tool: false,
  template_free: true,
  template_lite: true,
  template_standard: false,
  template_office365: false,
  template_salesforce: false,
  feature_faq_file: true,
  feature_carousel: true,
  feature_variable_operation: false,
  feature_integration: false,
  feature_task: false,
  feature_operator: false,
  feature_download_log: false,
  feature_import_export: true,
  feature_custom_icon: false,
  feature_feedback: false,
  feature_analytics: false,
  feature_restricted_access: false,
  feature_oauth: false,
  feature_directline_message_format: false,
  feature_line_switcher_api: false,
  feature_line_account_link: false,
  feature_line_pnp: false,
  feature_advanced_machine_learning: true,
  feature_teams_restricted_access: false,
  feature_push_api: false,
  feature_web_account_link_api: false,
  feature_translation: false,
  feature_http_integration_status_code_handing: false,
  feature_operation_history: false,
  feature_output_log: false,
  feature_faq_generator: false,
  feature_large_faq: false,
  feature_aitalk: false,
  feature_openai_default: false,
  feature_amazon_bedrock: false,
  feature_logging_url_access: false,
  payment_change_plan: false,
}

const standard = {
  ...lite,
  platform_line_works: true,
  platform_azure_bot_service: true,
  platform_direct_line: true,
  platform_teams: true,
  platform_hangouts: true,
  integration_http: true,
  integration_email: true,
  integration_google_spreadsheet: true,
  integration_excel: true,
  integration_tool: true,
  template_standard: true,
  feature_integration: true,
  feature_operator: true,
  feature_download_log: true,
  feature_custom_icon: true,
  feature_feedback: true,
  feature_analytics: true,
  feature_teams_restricted_access: true,
  feature_output_log: true,
  feature_faq_generator: true,
  feature_logging_url_access: true,
}

const advance = {
  ...standard,
  platform_skype_for_business: true,
  integration_office365: true,
  integration_salesforce: true,
  template_office365: true,
  feature_restricted_access: true,
  feature_variable_operation: true,
  feature_task: true,
  feature_oauth: true,
  feature_translation: true,
  feature_http_integration_status_code_handing: true,
  feature_operation_history: true,
  feature_large_faq: true,
  feature_openai_default: true,
  feature_amazon_bedrock: true,
}

const partner = {
  ...advance,
}

export const permissions = {
  free: free,
  lite: lite,
  standard: standard,
  trial: {
    ...advance,
    payment_change_plan: true,
  },
  'trial-beta': {
    ...advance,
    payment_change_plan: true,
  },
  advance: advance,
  partner: partner,
  'knowledge-plus': {},
  'line-customer-connect': {
    ...advance,
    platform_web: false,
    platform_line: true,
    platform_slack: false,
    platform_line_works: false,
    platform_azure_bot_service: false,
    platform_direct_line: false,
    platform_teams: false,
    platform_skype_for_business: false,
    platform_mobilus: false,
    platform_api_v1: false,
    platform_hangouts: false,
    feature_teams_restricted_access: false,
  },
  mobiagent: {
    ...advance,
    platform_web: false,
    platform_line: false,
    platform_slack: false,
    platform_line_works: false,
    platform_azure_bot_service: false,
    platform_direct_line: false,
    platform_teams: false,
    platform_skype_for_business: false,
    platform_mobilus: true,
    platform_api_v1: false,
    platform_hangouts: false,
    feature_teams_restricted_access: false,
  },
  salesforce: {
    ...advance,
    platform_web: true,
    platform_line: true,
    platform_slack: true,
    platform_line_works: true,
    platform_azure_bot_service: true,
    platform_direct_line: true,
    platform_teams: true,
    platform_skype_for_business: false,
    platform_mobilus: false,
    platform_api_v1: false,
    platform_hangouts: true,
    integration_salesforce: true,
    integration_salesforce_advance: true,
    template_salesforce: true,
    feature_oauth: true,
    feature_push_api: true,
    feature_teams_restricted_access: true,
  },
  '1-on-1-support': {
    ...advance,
    platform_web: false,
    platform_line: false,
    platform_slack: false,
    platform_line_works: false,
    platform_azure_bot_service: false,
    platform_direct_line: false,
    platform_teams: false,
    platform_skype_for_business: false,
    platform_mobilus: false,
    platform_api_v1: true,
    platform_hangouts: false,
    feature_directline_message_format: true,
    feature_teams_restricted_access: false,
  },
  'knowledge-plus-option': {},
  'line-switcher-api-option': {
    integration_line_switcher: true,
    feature_line_switcher_api: true,
  },
  'mobiagent-option': {
    platform_mobilus: true,
  },
  'salesforce-option': {
    integration_salesforce: true,
    integration_salesforce_advance: true,
    template_salesforce: true,
    feature_oauth: true,
  },
  'api-v1-option': {
    platform_api_v1: true,
    feature_directline_message_format: true,
  },
  'okbiz-option': {
    integration_okbiz: true,
  },
  'line-account-link-option': {
    feature_line_account_link: true,
  },
  'line-pnp-option': {
    feature_line_pnp: true,
  },
  'notification-api-option': {
    feature_push_api: true,
  },
  'web-account-link-api-option': {
    feature_web_account_link_api: true,
  },
  'idp-support-option': {
    feature_idp_support: true,
  },
  'translation-option': {
    feature_translation: true,
  },
  'aitalk-option': {
    feature_aitalk: true,
  },
}

export const experimentalFeatures = [
  'platform_direct_line',
  'platform_skype_for_business',
  'integration_excel',
  'template_office365',
  'template_salesforce',
  'feature_task',
  'feature_endpoint',
  'feature_directline_message_format',
  'feature_teams_restricted_access',
]
