import lodash from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, propTypes } from 'redux-form'
import PropTypes from 'prop-types'
import moment from 'moment'
import { UAParser } from 'ua-parser-js'
import classnames from 'classnames'

import darken from '../utils/colorConverter'
import Config from '../../helpers/config'
import { isPermittedSpeaker } from '../../helpers/permission'
import { getBrowserLanguageCode, localeToLanguage } from '../../helpers/language'
import { removeNotice, removeNotices } from '../../actions/notice'
import { fetchVoiceSynthFile } from '../../actions/voice_synth'
import ChatRoom from '../../components/chat/ChatRoom'
import {
  setChatStatus,
  prefetchApplication,
  joinApplication,
  connectApplication,
  setCurrentChannel,
  setFeatures,
  restoreMessages,
  fetchMessages,
  storeMessage,
  sendRawMessage,
  updateUnreadStatus,
  setAutoScroll,
  fetchCandidates,
  clearCandidates,
  selectPrevCandidate,
  selectNextCandidate,
  selectCandidate,
  startBotTyping,
  stopBotTyping,
  preventBotTypingMessage,
  allowBotTypingMessage,
  startOperatorTyping,
  stopOperatorTyping,
  changeNoticeMode,
  changeSpeaker,
} from '../../actions/embedded'
import WebSocketDevice from '../../helpers/awsIoT'
import MessagingAPIError from '../../helpers/requestMessagingAPI'
import { hasSavedMessages, removeExpiredMessages } from '../../helpers/persistMessages'
import ReactLoader from 'react-loader'

const validate = (data, props) => {
  //  Skip validation while pausing
  if (props.status === 'paused') return {}

  const errors = {}
  if (!data.text) {
    errors.text = 'validate.required'
  }

  return errors
}

export class EmbeddedChat extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    status: PropTypes.string,
    messages: PropTypes.array,
    application_token: PropTypes.string.isRequired,
    link_token: PropTypes.string,
    channel_uuid: PropTypes.string,
    handleRefresh: PropTypes.func,
    notices: PropTypes.array,
    isSimulator: PropTypes.bool,
    referrer: PropTypes.string,
    formValues: PropTypes.object,

    position: PropTypes.oneOf(['position-left', 'position-right', 'frame']),
    isImmediate: PropTypes.bool,
    isAutoScroll: PropTypes.bool,
    showBotTypingMessage: PropTypes.bool,
    isOperatorTyping: PropTypes.bool,

    theme: PropTypes.string,
    autoHideScrollbar: PropTypes.bool,
    fontSize: PropTypes.string,
    colors: PropTypes.object,
    title: PropTypes.string,
    features: PropTypes.object,
    params: PropTypes.string,

    noticeMode: PropTypes.string.isRequired,
    messageCallbackEnabled: PropTypes.bool,
    speechRecognitionDisabled: PropTypes.bool,
    speechRecognitionContinuously: PropTypes.bool,
    speechRecognitionSendWait: PropTypes.number,
    speechRecognitionTimeBarDelay: PropTypes.number,
    speechSynthesisDisabled: PropTypes.bool,
    speechVolumeCallbackEnabled: PropTypes.bool,

    inputTextPlaceholder: PropTypes.string,
    inputTextDisabled: PropTypes.bool,
  }

  static defaultProps = {
    messages: [],
    handleRefresh: () => {},
    isSimulator: false,
    status: 'initializing',
    messageCallbackEnabled: false,
    speechRecognitionDisabled: false,
    speechRecognitionContinuously: false,
    speechSynthesisDisabled: false,
    speechVolumeCallbackEnabled: false,
  }

  constructor() {
    super()
    this.lastRingedAt = null
    this.postingSpeechVolumeTimer = null
    this.alreadyNotifiedMessages = []
    this.alreadyPostedMessages = []

    this.voiceSynthPromises = []

    // Initialize audio resource
    if (window.AudioContext && Blob.prototype.arrayBuffer) {
      this.audioContext = new window.AudioContext()
      this.analyser = new AnalyserNode(this.audioContext)
      this.analyser.fftSize = 128
    }
  }

  componentDidMount() {
    const {
      dispatch,
      status,
      application_token,
      link_token,
      channel_uuid,
      position,
      isImmediate,
      theme,
      colors,
      features,
      speechVolumeCallbackEnabled,
    } = this.props

    window.addEventListener('beforeunload', () => {
      this.stopReadingOutByVoiceSynth()
      if (window.speechSynthesis) {
        window.speechSynthesis.cancel()
      }
    })
    window.addEventListener('message', this.onChangeEmbeddedState)

    if (status === 'error') return

    removeExpiredMessages({ days: 7, ignoreChannels: [channel_uuid] })

    if (position === 'frame' && (!features.prefetch || !isImmediate)) {
      dispatch(setChatStatus('paused'))
      return
    }

    if (speechVolumeCallbackEnabled) {
      this.postingSpeechVolumeTimer = setInterval(this.postSpeechVolume, 100)
    }

    if (!channel_uuid || !features.keepConversationHistory) {
      //  Prefetch or fetch initial messages when connect to new channel
      if (features.prefetch) {
        dispatch(setChatStatus('prefetching'))
        const language = getBrowserLanguageCode()
        dispatch(prefetchApplication(application_token, link_token, language))
          .then(response => {
            //  Fallback to connect for unset initial messages
            if (response.initial_messages == null) {
              this.connect()
              return
            }
            dispatch(setChatStatus('prefetched'))
          })
          .catch(() => {
            dispatch(setChatStatus('prefetched'))
          })
      } else {
        this.connect()
      }
    } else {
      //  Restore or fetch previous messages when connect to exists channel with channel_uuid
      dispatch(restoreMessages(channel_uuid))

      const isNeedConnect = () => {
        // Prefetch is disabled
        if (!features.prefetch) return true
        //  Open chat from operation check URL
        if (window.parent === window) return true
        // Chat in iframe without start button
        if (position === 'frame' && isImmediate) return true
        // Saved messages are nothing
        if (!hasSavedMessages(channel_uuid)) return true

        return false
      }

      if (isNeedConnect()) {
        this.connect()
      } else {
        dispatch(setChatStatus('prefetched'))
      }
    }

    const sendButtonBgHoverColor = colors.sendButton && darken(colors.sendButton, 20)

    let botFooterButtonBorder = 'none'
    if (colors.botBalloon && colors.botBalloon === '#ffffff') {
      botFooterButtonBorder = 'solid 1px #9e9e9e'
    }

    let clientUndoButtonBorder = 'solid 1px #9e9e9e'
    if (colors.userBalloon && colors.userBalloon !== '#ffffff') {
      clientUndoButtonBorder = 'none'
    }

    const style = `
      <style>
        {/* background */}
        div#root>.dm-chat {background: ${colors.background};}
        div#root>.dm-chat .chat .timestamp {color: ${colors.backgroundFont};}
        {/* bot balloon */}
        .dm-chat .chat>.server .footer-border { background-color: ${colors.botBalloon} !important; }
        .dm-chat .chat>.server .footer-btns-wrapper { background-color: ${colors.botBalloon} !important; }
        .dm-chat .chat>.server .content { background-color: ${colors.botBalloon} !important; }
        .dm-chat .chat>.server .content:before { border-top-color: ${colors.botBalloon} !important; }
        .dm-chat .chat>.server .content .content-footer { background-color: ${colors.botBalloon} !important; }
        .dm-chat .chat>.server .content .footer-btn { border: ${botFooterButtonBorder}; }
        .dm-chat .chat>.server.is-confirm .panel {border-color: ${colors.botBalloon};}
        .dm-chat .chat>.server.is-confirm .panel:before {border-top-color: ${colors.botBalloon};}
        .dm-chat .chat>.server.is-confirm .panel .panel-heading {
          background: ${colors.botBalloon};
          border-color: ${colors.botBalloon};
        }
        .dm-chat .chat>.server.is-confirm .panel .panel-border {background: ${colors.botBalloon};}
        .dm-chat .chat>.server.is-confirm .panel .panel-footer {background: ${colors.botBalloon};}
        .dm-chat .chat>.server.is-confirm .panel .footer-btn {border: ${botFooterButtonBorder} !important;}
        .dm-chat .chat>.server .content .text {color: ${colors.botBalloonFont};}
        .dm-chat .chat>.server .dm-panel.panel-default .panel-heading .title {color: ${colors.botBalloonFont};}
        .dm-chat>.dm-container>.note { background-color: ${colors.botBalloon} !important; }
        .dm-chat>.dm-container>.note { color: ${colors.botBalloonFont};}
        {/* user balloon */}
        .dm-chat .chat>.client .footer-border { background-color: ${colors.userBalloon} !important; }
        .dm-chat .chat>.client .content { background-color: ${colors.userBalloon} !important; }
        .dm-chat .chat>.client .content:before { border-top-color: ${colors.userBalloon} !important; }
        .dm-chat .chat>.client .content .text {color: ${colors.userBalloonFont};}
        .dm-chat .chat>.client .content .panel-border { background-color: ${colors.userBalloon} !important; }
        .dm-chat .chat>.client .content .footer-btn { border: ${clientUndoButtonBorder}; }
        {/* send button*/}
        .dm-chat .dm-chat-footer .input-group-btn:nth-of-type(2) button {
          background: ${colors.sendButton} !important;
          border-color: ${colors.sendButton} !important;
        }
        .dm-chat .dm-chat-footer .input-group-btn:nth-of-type(2) button:hover {
          background: ${sendButtonBgHoverColor} !important;
          border-color: ${sendButtonBgHoverColor} !important;
        }
        .dm-chat .dm-chat-footer .input-group-btn:nth-of-type(2) button {color: ${colors.sendButtonFont};}
        .dm-chat .dm-chat-footer .input-group-btn:nth-of-type(2) button:hover {color: ${colors.sendButtonFont}!important;}

        .dm-chat.paused .foreground {
          background: ${colors.background} !important;
        }
        .dm-chat.paused .title {
          color: ${colors.backgroundFont} !important;
        }
        .dm-chat.paused input[type="button"] {
          background: ${colors.sendButton} !important;
          border-color: ${colors.sendButton} !important;
          colors: ${colors.sendButtonFont} !important;
        }
        .dm-chat.paused input[type="button"]:hover {
          background: ${sendButtonBgHoverColor} !important;
          border-color: ${sendButtonBgHoverColor} !important;
        }
      </style>
    `
    theme === 'custom' && document.head.insertAdjacentHTML('beforeend', style)
  }

  onChangeEmbeddedState = message => {
    const { status, channel_uuid, features } = this.props

    if (message.data.type !== 'EmbeddedStatusChanged') return

    if (message.data.status === 'opened' && status === 'prefetched' && channel_uuid) {
      this.connect(!channel_uuid || !features.keepConversationHistory)
    }

    if (window.parent) {
      const payload = { type: 'EmbeddedStatusChangedAck', timestamp: message.data.timestamp }
      window.parent.postMessage(payload, '*')
    }
  }

  connect = (no_reply = false, ignoreChannelUuid = false) => {
    const { dispatch, application_token, link_token, params, referrer, handleRefresh, features } = this.props

    const channel_uuid = ignoreChannelUuid ? undefined : this.props.channel_uuid

    let params_dict
    try {
      params_dict = JSON.parse(params)
    } catch (e) {
      params_dict = undefined
    }

    //  Clear previous errors
    dispatch(removeNotices({ source: 'messaging' }))

    this.websocket = new WebSocketDevice('ap-northeast-1', this)

    dispatch(setChatStatus('connecting'))
    return dispatch(
      connectApplication(application_token, channel_uuid, referrer, features.authenticationType)
    )
      .then(response => {
        this.isNewUser = channel_uuid !== response.channel_uuid
        if (no_reply || !features.keepConversationHistory) {
          dispatch(setChatStatus('running'))
          return response
        } else {
          //  Fetch previous messages when connect to exists channel with channel_uuid
          return dispatch(fetchMessages(response.channel_uuid, null, link_token))
            .then(() => {
              handleRefresh(response.channel_uuid)
              dispatch(setChatStatus('running'))
            })
            .then(() => response)
        }
      })
      .then(response => {
        if (!features.disableWebsocket) {
          return this.connectWebsocket().then(() => {
            if (this.websocket.device) {
              this.websocket.device.subscribe(response.channel_uuid, { qos: 1 })
            }
            return response
          })
        } else {
          return this.startPolling().then(() => response)
        }
      })
      .then(response => {
        if (!this.needJoin(response)) return response

        const joinParameters = {
          variables: params_dict,
          language: getBrowserLanguageCode(),
        }
        localStorage.setItem(`joinParameters.${response.channel_uuid}`, JSON.stringify(joinParameters))

        return dispatch(
          joinApplication(
            application_token,
            response.channel_uuid,
            referrer,
            no_reply,
            link_token,
            params_dict,
            features.authenticationType,
            response.need_join,
            features.keepConversationHistory,
            getBrowserLanguageCode()
          )
        ).then(() => response)
      })
  }

  needJoin = response => {
    const { features, params } = this.props
    const joinParameters = JSON.parse(localStorage.getItem(`joinParameters.${response.channel_uuid}`) || '{}')
    let params_dict
    try {
      params_dict = JSON.parse(params)
    } catch (e) {
      params_dict = undefined
    }

    if (response.need_join) return true
    if (features.authenticationType === 'saml') return true
    if (features.authenticationType === 'oidc') return true
    if (!features.keepConversationHistory) return true
    if (!lodash.isEqual(params_dict, joinParameters.variables)) return true
    if (getBrowserLanguageCode() !== joinParameters.language) return true
    return false
  }

  connectWebsocket = () => {
    const handlers = {
      message: this.message,
    }
    return this.websocket.connect(Config.IdentityPoolId, handlers).catch(() => {
      //  Fallback to polling via HTTP instead of failed websocket
      this.startPolling()
    })
  }

  startPolling = () => {
    const { features } = this.props

    this.pollingCount = 0
    if (features.keepConversationHistory) {
      //  Fetch messages after join message has been processed in background API
      //  because suppress clear prefetched messages by RECEIVE_MESSAGES reducer with empty or client messages only
      //  TODO: Implement any method to get finish of processing in background API.
      this.pollingMessageTimer = setTimeout(this.pollingMessage, 3000)
    } else {
      //  Fetch messages after join message has been processed in background API
      //  because suppress fetching previous messages before clear previous messages in background API
      //  TODO: Implement any method to get finish of processing in background API.
      this.pollingMessageTimer = setTimeout(this.pollingMessage, 3000)
    }
    return Promise.resolve()
  }

  message = (topic, payload) => {
    const { dispatch, handleRefresh, channel_uuid } = this.props
    const message = JSON.parse(payload)
    message.timestamp = moment(message.timestamp)
    dispatch(storeMessage(message))

    if (message.sender_type === 'bot') {
      dispatch(preventBotTypingMessage())

      if (message.type === 'typing') {
        dispatch(startBotTyping())
        clearTimeout(this.botTypingTimeoutTimer)
        this.botTypingTimeoutTimer = setTimeout(() => {
          dispatch(stopBotTyping())
        }, 90000)

        clearTimeout(this.allowBotTypingMessageTimer)
        this.allowBotTypingMessageTimer = setTimeout(() => {
          dispatch(allowBotTypingMessage())
        }, 1300)
      } else if (message.type === 'done') {
        dispatch(stopBotTyping())
        clearTimeout(this.allowBotTypingMessageTimer)
        clearTimeout(this.botTypingTimeoutTimer)
      } else {
        clearTimeout(this.allowBotTypingMessageTimer)
        this.allowBotTypingMessageTimer = setTimeout(() => {
          dispatch(allowBotTypingMessage())
        }, 2000)
      }
    }

    if (message.type === 'operator_typing') {
      this.startOperatorTyping()
    } else if (message.type === 'operator_stop_typing') {
      this.stopOperatorTyping()
    }

    if (message.sender_type !== 'client') {
      //  Refresh room status with throttling
      clearTimeout(this.refreshTimer)
      this.refreshTimer = setTimeout(() => {
        handleRefresh(channel_uuid)
      }, 1000)

      this.postMessageToParentWindow(message)
    }

    this.notifyReceivedMessages([message])
  }

  postMessageToParentWindow = message => {
    if (!this.props.messageCallbackEnabled || window === window.parent) return

    if (!lodash.includes(this.alreadyPostedMessages, message.uuid)) {
      window.parent.postMessage({ message: message.content }, '*')
      this.alreadyPostedMessages.push(message.uuid)
    }
  }

  pollingMessage = () => {
    const { dispatch, channel_uuid, link_token, messages, features } = this.props
    //  Regress to websocket if succeed connection
    if (!features.disableWebsocket) {
      if (!this.websocket || this.websocket.status === 'connected') return
    }

    //  Fetch message via HTTP request
    if (!channel_uuid) {
      clearTimeout(this.pollingMessageTimer)
      this.pollingMessageTimer = setTimeout(this.pollingMessage, 1000)
      return
    }

    const lastMessage = lodash.last(
      lodash.filter(
        messages,
        message => message.type !== 'reset' && !message.temporary && !message.prefetched
      )
    )
    const lastReceived = lastMessage ? lastMessage.timestamp.toISOString() : null

    dispatch(fetchMessages(channel_uuid, lastReceived, link_token)).then(fetchedMessages => {
      fetchedMessages.forEach(message => {
        this.postMessageToParentWindow(message)
      })

      this.notifyReceivedMessages(fetchedMessages)
      const lastSent = lodash.last(
        lodash.filter(lodash.concat(messages, fetchedMessages), message => !message.prefetched)
      )?.timestamp

      let elapsed
      if (lastSent) {
        elapsed = new Date() - new Date(lastSent)
      } else {
        if (this.isNewUser && this.pollingCount < 10) {
          elapsed = 0
        } else {
          elapsed = 5 * 60 * 1000
        }
      }

      let wait
      if (elapsed < 20 * 1000) {
        // below 20 seconds -> fetch after 1 second
        wait = 1 * 1000
      } else if (elapsed < 1 * 60 * 1000) {
        // below 1 minute -> fetch after 5 seconds
        wait = 5 * 1000
      } else if (elapsed < 5 * 60 * 1000) {
        // below 5 minutes -> fetch after 1 minute
        wait = 60 * 1000
      } else {
        // above 5 minutes -> fetch after 5 minutes
        wait = 5 * 60 * 1000
      }

      clearTimeout(this.pollingMessageTimer)
      this.pollingCount += 1
      this.pollingMessageTimer = setTimeout(this.pollingMessage, wait)
    })
  }

  unlockAudio = () => {
    if (this.audioUnlocked) return

    if (this.audioContext) {
      const buffer = this.audioContext.createBuffer(1, 1, 22050)
      const source = this.audioContext.createBufferSource()
      source.buffer = buffer
      source.connect(this.audioContext.destination)
      source.start(0)
    }

    if (window.speechSynthesis) {
      const utterance = new SpeechSynthesisUtterance('')
      window.speechSynthesis.speak(utterance)
    }

    this.audioUnlocked = true
  }

  notifyReceivedMessages = messages => {
    if (this.props.noticeMode === 'speech') {
      this.notifyReceivedMessagesWithReadOut(messages)
    }
    if (this.props.noticeMode === 'sound') {
      this.notifyReceivedMessagesWithSound(messages)
    }
  }

  notifyReceivedMessagesWithReadOut = messages => {
    messages.forEach(message => {
      if (message.sender_type === 'client') return

      if (lodash.includes(this.alreadyNotifiedMessages, message.uuid)) return
      this.alreadyNotifiedMessages.push(message.uuid)

      const { t } = this.context
      let utteranceTexts = []
      if (message.type === 'text' || message.type === 'datepicker') {
        const sentences = (message.content.text || '').split(/[。\n]/)
        utteranceTexts.push(...sentences)
      }
      if (message.type === 'image') {
        utteranceTexts.push(message.content.title || '')
      }
      if (message.type === 'confirm') {
        utteranceTexts.push(message.content.title || '')
        utteranceTexts.push(message.content.text || '')
        utteranceTexts.push(t('chatMessage.yes'))
        utteranceTexts.push(t('chatMessage.no'))
      }
      if (message.type === 'request_feedback') {
        utteranceTexts.push(message.content.title || '')
        utteranceTexts.push(message.content.text || '')
        if (message.content.phase === 'initial') {
          utteranceTexts.push(t('chatMessage.yes'))
          utteranceTexts.push(t('chatMessage.no'))
        }
      }
      if (message.type === 'choose') {
        utteranceTexts.push(message.content.title || '')
        utteranceTexts.push(message.content.text || '')

        message.content.actions.forEach(action => {
          utteranceTexts.push(action.label || '')
        })
      }
      if (message.type === 'carousel') {
        message.content.carousel_items.forEach(content => {
          utteranceTexts.push(content.title || '')
          utteranceTexts.push(content.text || '')

          content.actions.forEach(action => {
            utteranceTexts.push(action.label || '')
          })
        })
      }
      if (message.type === 'item_list') {
        message.content.items.forEach(content => {
          utteranceTexts.push(content.text || '')
        })
      }
      if (message.type === 'form') {
        if (message.content.phase === 'initial') {
          utteranceTexts.push(message.content.title || '')

          message.content.fields.forEach(field => {
            utteranceTexts.push(field.label || '')
          })

          message.content.buttons.forEach(button => {
            utteranceTexts.push(button.label || '')
          })
        }
      }

      const contentItems = lodash.compact(
        utteranceTexts.map(item => {
          return item.replace(/(?:https?|notes):\/\/[^\s]*/gi, 'URL')
        })
      )
      this.readOut(contentItems)
    })
  }

  readOut = texts => {
    const { dispatch, features, formValues } = this.props

    if (lodash.isEmpty(texts)) return

    const language = features.enableTranslation ? getBrowserLanguageCode() : features.language
    if (!lodash.startsWith(language, 'ja')) {
      this.readOutByWebSpeechApi(texts.join())
      return
    }

    if (!this.audioContext) {
      this.readOutByWebSpeechApi(texts.join())
      return
    }

    const validVoiceSynthSpeakerNames = [
      'miyabi',
      'yamato',
      'nozomi',
      'seiji',
      'chihiro',
      'yuuto',
      'kasukabe_tsumugi',
      'amehare_hau',
      'kurono_takehiro',
      'aoyama_ryuusei',
    ]

    if (!lodash.includes(validVoiceSynthSpeakerNames, formValues.speaker)) {
      this.readOutByWebSpeechApi(texts.join())
      return
    }

    const willReadout = lodash.isEmpty(this.voiceSynthPromises)

    texts.forEach(text => {
      this.voiceSynthPromises.push(dispatch(fetchVoiceSynthFile(text, formValues.speaker)))
    })
    if (willReadout) {
      this.readOutByVoiceSynth(this.voiceSynthPromises[0])
    }
  }

  readOutByVoiceSynth = promise => {
    return promise
      .then(blob => {
        if (!this.audioContext) return

        // Target promise is already cleared
        if (!lodash.includes(this.voiceSynthPromises, promise)) return

        blob.arrayBuffer().then(arrayBuffer =>
          this.audioContext.decodeAudioData(
            arrayBuffer,
            audioBuffer => {
              this.audioBufferSource = this.audioContext.createBufferSource()
              this.audioBufferSource.connect(this.audioContext.destination)
              this.audioBufferSource.connect(this.analyser)
              this.audioBufferSource.onended = this.onendedReadingOutByVoiceSynth
              this.audioBufferSource.buffer = audioBuffer

              this.audioBufferSource.start()
            },
            error => {
              throw new Error(error)
            }
          )
        )
      })
      .catch(() => {
        // Remove failed promise
        this.voiceSynthPromises.shift()

        if (!lodash.isEmpty(this.voiceSynthPromises)) {
          this.readOutByVoiceSynth(this.voiceSynthPromises[0])
        }
      })
  }

  stopReadingOutByVoiceSynth = () => {
    // When read out by VoiceSynth
    if (lodash.isEmpty(this.voiceSynthPromises)) return

    this.voiceSynthPromises = []

    // Stop reading out
    this.audioBufferSource.stop()
  }

  onendedReadingOutByVoiceSynth = () => {
    // Remove used promise
    this.voiceSynthPromises.shift()

    if (!lodash.isEmpty(this.voiceSynthPromises)) {
      setTimeout(() => {
        this.readOutByVoiceSynth(this.voiceSynthPromises[0])
      }, 500)
    }
  }

  postSpeechVolume = () => {
    if (window === window.parent) return

    if (this.analyser === undefined) {
      window.parent.postMessage({ volume: 0 }, '*')
      return
    }

    const dataArray = new Float32Array(this.analyser.fftSize)
    this.analyser.getFloatTimeDomainData(dataArray)

    const squaredSamples = lodash.map(dataArray, x => Math.pow(x, 2))
    const squaredSampleAverage = lodash.sum(squaredSamples) / dataArray.length
    const rootMeanSquare = Math.sqrt(squaredSampleAverage)

    window.parent.postMessage({ volume: rootMeanSquare }, '*')
  }

  readOutByWebSpeechApi = text => {
    const { features } = this.props

    if (!window.speechSynthesis) return

    const utterance = new SpeechSynthesisUtterance(text)
    if (features.enableTranslation) {
      utterance.lang = getBrowserLanguageCode()
    } else {
      utterance.lang = localeToLanguage(features.language)
    }

    window.speechSynthesis.speak(utterance)
  }

  notifyReceivedMessagesWithSound = messages => {
    const noticeableMessages = lodash.reject(
      messages,
      message =>
        message.sender_type === 'client' ||
        message.type === 'operator_typing' ||
        message.type === 'operator_stop_typing' ||
        lodash.includes(this.alreadyNotifiedMessages, message.uuid)
    )

    if (lodash.isEmpty(noticeableMessages)) return

    const threshold = 5000
    const isElapsed = new Date() - this.lastRingedAt >= threshold
    const hasOperatorMessage = lodash.some(noticeableMessages, message => message.sender_type === 'operator')
    if (isElapsed || hasOperatorMessage) {
      this.playSound()
      this.lastRingedAt = new Date()
    }
  }

  playSound = () => {
    const oscillatorNode = this.audioContext.createOscillator()
    const gainNode = this.audioContext.createGain()

    oscillatorNode.type = 'sine'
    oscillatorNode.frequency.setValueAtTime(783, this.audioContext.currentTime)
    oscillatorNode.frequency.setValueAtTime(1046, this.audioContext.currentTime + 0.1)

    gainNode.gain.setValueAtTime(0.1, this.audioContext.currentTime)
    gainNode.gain.linearRampToValueAtTime(0, this.audioContext.currentTime + 0.5)

    oscillatorNode.connect(gainNode)
    gainNode.connect(this.audioContext.destination)

    oscillatorNode.start()
    oscillatorNode.stop(this.audioContext.currentTime + 0.5)
  }

  startOperatorTyping = () => {
    this.props.dispatch(startOperatorTyping())
    clearTimeout(this.typingTimer)
    this.typingTimer = setTimeout(this.stopOperatorTyping, 60000)
  }

  stopOperatorTyping = () => {
    this.props.dispatch(stopOperatorTyping())
    clearTimeout(this.typingTimer)
    this.typingTimer = null
  }

  updateUnreadStatus = () => {
    this.props.dispatch(updateUnreadStatus())
  }

  setAutoScroll = isAutoScroll => {
    if (this.props.isAutoScroll !== isAutoScroll) {
      this.props.dispatch(setAutoScroll(isAutoScroll))
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    if (this.websocket) {
      this.websocket.close()
    }
    dispatch(setCurrentChannel(undefined))
    dispatch(setFeatures({}))
    clearTimeout(this.pollingMessageTimer)
    clearTimeout(this.refreshTimer)
    dispatch(stopBotTyping())
    clearTimeout(this.allowBotTypingMessageTimer)
    clearTimeout(this.botTypingTimeoutTimer)
    this.stopReadingOutByVoiceSynth()
    clearInterval(this.postingSpeechVolumeTimer)
    this.postingSpeechVolumeTimer = null
    if (window.speechSynthesis) {
      window.speechSynthesis.cancel()
    }
  }

  onErrorSendMessage = (e, data, type = 'text', image_file = null, reconnect = true) => {
    const { link_token, dispatch, notices, referrer, features } = this.props

    if (e.constructor === MessagingAPIError || e.response.status !== 404) throw e
    if (!reconnect) throw e

    notices.forEach(notice => dispatch(removeNotice(notice.id)))
    this.websocket.close()

    this.connect(true, true).then(response => {
      return dispatch(
        sendRawMessage(
          response.channel_uuid,
          type,
          data,
          referrer,
          link_token,
          image_file,
          features.authenticationType
        )
      ).catch(e => this.onErrorSendMessage(e, data, type, image_file, false))
    })
  }

  queryCandidates = text => {
    const { dispatch, messages, features, isSimulator, application_token, link_token } = this.props
    if (isSimulator) return
    if (!features.suggestion) return

    clearTimeout(this.queryCandidatesTimer)

    if (!text || text.length < 2 || text.includes('\n')) {
      return dispatch(clearCandidates())
    }

    this.queryCandidatesTimer = setTimeout(() => {
      const message = lodash.findLast(messages, { type: 'classifying_state' })
      if (message && message.content.index_name) {
        dispatch(fetchCandidates(application_token, message.content.index_name, text, link_token))
      }
    }, 100)
  }

  clearCandidates = () => {
    this.props.dispatch(clearCandidates())
  }

  selectPrevCandidate = () => {
    this.props.dispatch(selectPrevCandidate())
  }

  selectNextCandidate = () => {
    this.props.dispatch(selectNextCandidate())
  }

  selectCandidate = index => {
    this.props.dispatch(selectCandidate(index))
  }

  decideCandidate = () => {
    const { candidates, selectedIndex } = this.props.suggestion
    if (selectedIndex == null) return

    const text = candidates[selectedIndex]
    const rawText = text.replace(/<em>(.*?)<\/em>/g, '$1')
    this.handleSendMessage({ text: rawText })
    this.props.dispatch(clearCandidates())
  }

  handleSendMessage = data => {
    if (!data.text && !data.value && !data.image_file) return

    const type = data.type || 'text'
    const content = {
      text: data.text,
      value: data.value,
      is_select: data.is_select,
      skip_translation: data.skip_translation,
    }

    return this.handleSendRawMessage(type, content, data.image_file)
  }

  handleSendRawMessage = (type, content, image_file = null) => {
    const { channel_uuid, link_token, referrer, dispatch, isAutoScroll, features } = this.props

    this.unlockAudio()

    //  Clear unread flag if bottom is displayed
    if (isAutoScroll) {
      this.updateUnreadStatus()
    }

    this.clearCandidates()

    const device = new UAParser().getResult().device.model
    if (device === 'iPhone') {
      document.activeElement.blur()
    } else {
      document.querySelector('[name="text"]').focus()
    }

    if (type === 'response_feedback') {
      this.props.change(`${content.replyToUUID}.feedbackReason`, '')
    } else {
      this.props.change('text', '')
    }

    this.lastRingedAt = null
    this.stopReadingOutByVoiceSynth()
    if (window.speechSynthesis) {
      window.speechSynthesis.cancel()
    }

    const content_with_language = {
      ...content,
      language: getBrowserLanguageCode(),
    }

    //  Connect websocket and application before send message
    if (!this.websocket) {
      this.connect(true).then(response => {
        return dispatch(
          sendRawMessage(
            response.channel_uuid,
            type,
            content_with_language,
            referrer,
            link_token,
            image_file,
            features.authenticationType
          )
        ).catch(e => this.onErrorSendMessage(e, content_with_language, type, image_file))
      })
    } else {
      return dispatch(
        sendRawMessage(
          channel_uuid,
          type,
          content_with_language,
          referrer,
          link_token,
          image_file,
          features.authenticationType
        )
      )
        .then(() => {
          //  Shorten wait for next fetching to get response for this utterance if websocket has been failed
          if (features.disableWebsocket || !this.websocket || this.websocket.status !== 'connected') {
            clearTimeout(this.pollingMessageTimer)
            this.pollingMessageTimer = setTimeout(this.pollingMessage, 1000)
          }
        })
        .catch(e => this.onErrorSendMessage(e, content_with_language, type, image_file))
    }
  }

  getErrorMessage = notice => {
    const { t } = this.context
    const code = notice.options.code || 500
    if (code < 400) return t('error.message.unexpectedError.message')
    if (code === 400) return t('error.message.unexpectedError.message')
    if (code >= 500 && code !== 503) return t('error.message.unexpectedError.message')

    return notice.message
  }

  changeNoticeMode = mode => {
    return this.props.dispatch(changeNoticeMode(this.props.application_token, mode))
  }

  changeSpeaker = speaker => {
    return this.props.dispatch(changeSpeaker(this.props.application_token, speaker))
  }

  onSpeechRecognition = text => {
    this.props.change('text', text)
  }

  render() {
    const {
      features,
      submitting,
      messages,
      handleSubmit,
      theme,
      autoHideScrollbar,
      fontSize,
      notices,
      status,
      isSimulator,
      formValues,
      isAutoScroll,
      showBotTypingMessage,
      isOperatorTyping,
      suggestion,
      noticeMode,
      speechRecognitionDisabled,
      speechRecognitionContinuously,
      speechRecognitionSendWait,
      speechRecognitionTimeBarDelay,
      speechSynthesisDisabled,
      inputTextPlaceholder,
      inputTextDisabled,
    } = this.props

    if (status === 'initializing') return <ReactLoader />
    if (status === 'prefetching') return <ReactLoader />
    if (status === 'paused') {
      return this.renderPaused()
    } else {
      let isError = false
      let noticeMessage
      if (notices.length > 0) {
        isError = true

        const notice = lodash.head(notices)
        noticeMessage = {
          uuid: notice.id,
          type: 'text',
          sender_type: 'bot',
          sender_uid: 'system',
          timestamp: notice.timestamp,
          content: {
            text: this.getErrorMessage(notice),
          },
        }
      }

      const containerClass = classnames({
        fit: true,
        embedded: true,
        'dm-chat': true,
        'force-normal': true,
        [theme]: theme,
        autoHideScrollbar: autoHideScrollbar,
        [fontSize]: fontSize,
      })

      const suggestionProps = {
        ...suggestion,
        queryCandidates: this.queryCandidates,
        clearCandidates: this.clearCandidates,
        selectPrevCandidate: this.selectPrevCandidate,
        selectNextCandidate: this.selectNextCandidate,
        selectCandidate: this.selectCandidate,
        decideCandidate: this.decideCandidate,
      }

      const visibleMessages = lodash.reject(messages, { type: 'classifying_state' })
      return (
        <div className={containerClass} onClick={this.clearCandidates}>
          <ChatRoom
            mode="client"
            isError={isError}
            submitting={submitting}
            messages={isError ? [...visibleMessages, noticeMessage] : visibleMessages}
            onSubmit={handleSubmit(this.handleSendMessage)}
            handleSendMessage={this.handleSendMessage}
            handleSendRawMessage={this.handleSendRawMessage}
            formValues={formValues}
            features={features}
            isSimulator={isSimulator}
            updateUnreadStatus={this.updateUnreadStatus}
            isAutoScroll={isAutoScroll}
            setAutoScroll={this.setAutoScroll}
            showBotTypingMessage={showBotTypingMessage}
            isOperatorTyping={isOperatorTyping}
            suggestion={suggestionProps}
            noticeMode={noticeMode}
            changeNoticeMode={this.changeNoticeMode}
            changeSpeaker={this.changeSpeaker}
            stopReadingOutByVoiceSynth={this.stopReadingOutByVoiceSynth}
            imageUploaderDisabled={features.restrictImageFileUploading}
            onSpeechRecognition={this.onSpeechRecognition}
            speechRecognitionDisabled={speechRecognitionDisabled}
            speechRecognitionContinuously={speechRecognitionContinuously}
            speechRecognitionSendWait={speechRecognitionSendWait}
            speechRecognitionTimeBarDelay={speechRecognitionTimeBarDelay}
            speechSynthesisDisabled={speechSynthesisDisabled}
            unlockAudio={this.unlockAudio}
            inputTextPlaceholder={inputTextPlaceholder}
            inputTextDisabled={inputTextDisabled}
          />
        </div>
      )
    }
  }

  renderPaused() {
    const { t } = this.context
    const { submitting, handleSubmit, theme, autoHideScrollbar, fontSize, title, colors } = this.props

    const containerClass = classnames({
      fit: true,
      embedded: true,
      'dm-chat': true,
      [theme]: theme,
      autoHideScrollbar: autoHideScrollbar,
      [fontSize]: fontSize,
      paused: true,
    })

    return (
      <div className={containerClass}>
        <div className="foreground">
          <div className="content">
            <div className="title">{title}</div>
            <div>
              <input
                className="dm-btn btn btn-primary start"
                style={{ backgroundColor: colors.sendButton, color: colors.sendButtonFont }}
                type="button"
                onClick={handleSubmit(() => this.connect())}
                value={t('embeddedChat.start')}
                disabled={submitting}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const EmbeddedChatForm = reduxForm({
  form: 'EmbeddedChat',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
  shouldError: ({ props, nextProps }) => props.status !== (nextProps || {}).status,
})(EmbeddedChat)

export const mapStateToProps = (state, props) => {
  const enabledFormMessages = lodash.filter(
    props.messages,
    message => message.type === 'form' && !message.content.disabled
  )

  const initialValues = {}
  lodash.forEach(enabledFormMessages, message => {
    const initialValue = {}
    lodash.forEach(message.content.fields, field => {
      if (field.type === 'text') {
        initialValue[field.name] = field.default_value
      } else if (field.type === 'text_list') {
        initialValue[`${field.name}`] = lodash.map(field.rows, row => row.default_value)
      } else if (field.type === 'select') {
        if (lodash.some(field.items, item => item.value.toString() === field.default_value)) {
          initialValue[field.name] = field.default_value
        }
      } else if (field.type === 'select_list') {
        initialValue[`${field.name}`] = lodash.map(field.rows, row => {
          if (!lodash.some(row.items, { value: row.default_value })) return undefined
          return row.default_value
        })
      } else if (field.type === 'multiple_select') {
        initialValue[`${field.name}`] = field.default_values
      }
    })

    initialValues[message.uuid] = initialValue
  })

  const speaker = state.chat.speaker[props.application_token] || props.features.defaultSpeakerName
  if (isPermittedSpeaker(speaker, state, props)) {
    initialValues['speaker'] = speaker
  } else {
    initialValues['speaker'] = 'web_speech_api'
  }

  let noticeMode = 'mute'
  if ((window.AudioContext && Blob.prototype.arrayBuffer) || window.speechSynthesis) {
    noticeMode = state.chat.noticeMode[props.application_token] || props.features.defaultNoticeMode || 'mute'
  }

  return {
    ...props,
    noticeMode: props.speechSynthesisDisabled && noticeMode === 'speech' ? 'mute' : noticeMode,
    initialValues,
  }
}

export default connect(mapStateToProps)(EmbeddedChatForm)
