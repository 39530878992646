import lodash from 'lodash'
import union from 'lodash/union'

const initialPagination = {
  accounts: {},
  users: {},
  applications: {},
  bots: {},
  domains: {},
  topics: {},
  endpoints: {},
  entities: {},
  faqs: {},
  faq_files: {},
  faq_categories: {},
  faq_items: {},
  integrations: {},
  tasks: {},
  interruptions: {},
  sample_templates: {},
  email_addresses: {},
  email_domains: {},
  oauth_clients: {},
  threads: {},
  prediction_failure_logs: {},
  learning_exclusion_words: {},
  plans: {},
  condition_groups: {},
  delivery_jobs: {},
}

export default (state = initialPagination, action) => {
  const updateTargetState = (state, action) => {
    if (action.type === 'RESPONSE_API') {
      let ids = (state || {}).ids || []
      if (!action.preserve) {
        ids = []
      }

      return {
        ...state,
        ids: lodash.sortBy(union(ids, action.response.result)),
      }
    }

    if (action.type === 'DELETE_CACHE') {
      if (!action.id) return {}
      const ids = (state || {}).ids || []
      return {
        ...state,
        ids: ids.filter(id => id !== action.id),
      }
    }

    return state
  }

  if (!lodash.includes(['RESPONSE_API', 'DELETE_CACHE'], action.type)) {
    return state
  }

  return {
    ...state,
    [action.target]: updateTargetState(state[action.target], action),
  }
}
