import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, propTypes } from 'redux-form'
import { decode } from 'jwt-simple'

import { fetchRooms } from '../actions/chat'
import { deleteSession } from '../actions/session'
import { selectAccount } from '../actions/user'
import DataBindingTable from '../components/common/DataBindingTable'
import Loader from '../components/common/Loader'

export class SelectAccount extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = { tableState: {} }
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const router = this.context.router

    if (!state.session.token) {
      router.replace('/login')
      return
    }

    const payload = decode(state.session.token, '', true)
    const sub = payload.sub || payload.identity
    if (sub.account_uuid) {
      router.replace('/')
    }
  }

  handleLogin = accountId => {
    const state = this.context.store.getState()
    const router = this.context.router
    const { dispatch } = this.props

    return dispatch(selectAccount(state.session.token, accountId))
      .then(() => router.push('/'))
      .then(() => {
        // Get the number of rooms which calling operator
        dispatch(fetchRooms(this.context.store.getState().session, { operator_state: 'calling' }))
      })
  }

  handleLogout = () => {
    const { dispatch } = this.props
    dispatch(deleteSession())
    this.context.router.push('/login')
  }

  updateTableState = (_path, _tableName, tableState) => {
    this.setState({ tableState: lodash.merge({}, this.state.tableState, tableState) })
  }

  render() {
    const { t } = this.context
    const { submitting, handleSubmit, accounts } = this.props

    let columns = [
      {
        Header: t('user.selectAccount.companyName'),
        id: 'company_name',
        accessor: 'company_name',
      },
      {
        Header: t('user.selectAccount.accountNumber'),
        id: 'account_number',
        accessor: 'account_number',
      },
      {
        Header: t('user.selectAccount.plan'),
        id: 'plan',
        accessor: account => t(`common.plans.${account.plan}`),
      },
      {
        Header: t('user.selectAccount.select'),
        id: 'login',
        accessor: 'id',
        Cell: props => (
          <button
            type="button"
            className="btn btn-primary btn-block dm-btn mini"
            disabled={submitting}
            onClick={handleSubmit(() => this.handleLogin(props.value))}
          >
            {t('user.selectAccount.select')}
          </button>
        ),
        maxWidth: 150,
        sortable: false,
      },
    ]

    return (
      <div>
        <Loader type="show" loaded={!submitting}>
          <DataBindingTable
            title={t('user.selectAccount.title')}
            items={accounts}
            columns={columns}
            tableState={this.state.tableState}
            updateTableState={this.updateTableState}
          />
          <button className="btn btn-primary dm-btn" disabled={submitting} onClick={this.handleLogout}>
            {t('common.logout')}
          </button>
        </Loader>
      </div>
    )
  }
}

const SelectAccountForm = reduxForm({
  form: 'SelectAccount',
})(SelectAccount)

const mapStateToProps = (_state, props) => {
  return {
    accounts: (props.location.state || {}).accounts,
  }
}

export default connect(mapStateToProps)(SelectAccountForm)
