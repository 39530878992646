import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FieldArray } from 'redux-form'
import uuid from 'uuid'

import TextMessageAction from './TextMessageAction'
import UploadImageAction from './UploadImageAction'
import AiAssistantAction from './AiAssistantAction'
import RunIntegrationAction from './RunIntegrationAction'
import CallOperatorAction from './CallOperatorAction'
import ConfirmAction from './ConfirmAction'
import ChooseAction from './ChooseAction'
import ExitTopicAction from './ExitTopicAction'
import SlotFillingAction from './SlotFillingAction'
import ChangeScenarioAction from './ChangeScenarioAction'
import ClassifyFaqAction from './ClassifyFaqAction'
import GenerateAnswerAction from './GenerateAnswerAction'
import ChooseScenarioAction from './ChooseScenarioAction'
import RerunScenarioAction from './RerunScenarioAction'
import ItemListAction from './ItemListAction'
import ChooseListAction from './ChooseListAction'
import CarouselAction from './CarouselAction'
import CarouselListAction from './CarouselListAction'
import FormAction from './FormAction'
import RegisterTaskAction from './RegisterTaskAction'
import AbortTaskAction from './AbortTaskAction'
import CompleteTaskAction from './CompleteTaskAction'
import VariableAction from './VariableAction'
import LogAction from './LogAction'
import AddNewAction from './AddNewAction'

export class ActionList extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    topicType: PropTypes.string,
    actions: PropTypes.array,
    integrations: PropTypes.array,
    entities: PropTypes.array,
    domains: PropTypes.array,
    topics: PropTypes.array,
    faqs: PropTypes.array,
    faqFiles: PropTypes.array,
    userVariables: PropTypes.array,
    chatbotConstants: PropTypes.array,
    variables: PropTypes.array,
    tasks: PropTypes.array,
    maxLength: PropTypes.number,
    onChangeSlotDefinition: PropTypes.func,
    onChangeImageActionType: PropTypes.func,
    onChangeConditionType: PropTypes.func,
    isLoadedImage: PropTypes.bool,
    onChangeImageMethod: PropTypes.func,
    onChangeImageFile: PropTypes.func,
    onClickImageReset: PropTypes.func,
    onChangeChooseScenarioImageFile: PropTypes.func,
    onChangeCarouselImageFile: PropTypes.func,
    onError: PropTypes.func,
    condition_groups: PropTypes.array,
    enableFeedback: PropTypes.bool,
    restrictedActions: PropTypes.array,
    isValidateCondition: PropTypes.bool,
  }

  static defaultProps = {
    topicType: 'normal',
    variables: [],
  }

  //  For IE11 compatibility
  componentDidUpdate(prevProps) {
    const prevActions = prevProps.actions || []
    const nextActions = this.props.actions || []
    if (prevActions.length !== nextActions.length) {
      const resizeEvent = window.document.createEvent('UIEvents')
      resizeEvent.initUIEvent('resize', true, false, window, 0)
      window.dispatchEvent(resizeEvent)
    }
  }

  onAdd = (fields, index) => type => {
    const { t } = this.context

    const values = { type, uuid: uuid.v4(), condition_type: '', condition_value: '' }
    if (type === 'integration') {
      values.exit_scenario_if_failed = true
      values.exceeded_action = 'nothing'
    }
    if (type === 'slot') values.slots = [undefined]
    if (type === 'operator') values.tags = [undefined]
    if (type === 'choose_scenario') values.image_method = 'upload'
    if (type === 'upload_image') values.image_method = 'upload'
    if (type === 'carousel') values.items = [{ image_method: 'upload', enable_default_action: false }]
    if (type === 'carousel_list') values.item = { enable_default_action: false }
    if (type === 'form') {
      values.fields = [{ arguments: { default_value_type: 'constant', data_type: 'constant' } }]
      values.buttons = [{ type: 'submit' }, { type: 'cancel' }]
    }
    if (type === 'exit_topic') values.is_all_terminate = false
    if (type === 'generate_answer') {
      values.tags = []
      values.prompt = t('topic.actionList.generate_answer.customizedPrompt.default')
    }
    if (type === 'register_task') values.variables = []
    if (type === 'variable') values.arguments = { value: '' }
    fields.insert(index + 1, values)
  }

  renderActions = ({
    topicType,
    actions,
    integrations,
    entities,
    domains,
    topics,
    faqs,
    faqFiles,
    tasks,
    onChangeImageMethod,
    onChangeImageFile,
    onClickImageReset,
    onChangeChooseScenarioImageFile,
    onChangeCarouselImageFile,
    isLoadedImage,
    userVariables,
    chatbotConstants,
    variables,
    maxLength,
    restrictedActions,
    isValidateCondition,
    onChangeSlotDefinition,
    onChangeImageActionType,
    onError,
    onChangeConditionType,
    fields,
    condition_groups,
    enableFeedback,
    meta: { error, submitFailed },
  }) => {
    if (!actions) return null

    const { t } = this.context
    const actionNodes = fields.map((name, index) => {
      const action = actions[index]
      if (!action) return null

      const props = {
        topicType,
        action,
        key: action.uuid,
        index,
        name,
        isFirst: index === 0,
        isLast: index === actions.length - 1,
        onMoveUp: index => fields.swap(index, index - 1),
        onMoveDown: index => fields.swap(index, index + 1),
        onRemove: index => fields.remove(index),
        onInsertAction: this.onAdd(fields, index),
        onChangeConditionType,
        onError,
        userVariables,
        chatbotConstants,
        variables: lodash.filter(variables, variable => variable.index < index),
        condition_groups,
        restrictedActions,
        isValidateCondition,
        disableAddNewAction: maxLength ? actions.length >= maxLength : false,
      }
      if (action.type === 'speak') {
        return <TextMessageAction {...props} />
      }
      if (action.type === 'upload_image') {
        return (
          <UploadImageAction
            {...props}
            onChangeImageMethod={onChangeImageMethod}
            onChangeImageFile={onChangeImageFile}
            onChangeImageActionType={onChangeImageActionType}
            isLoadedImage={isLoadedImage}
          />
        )
      }
      if (action.type === 'ai_assistant') {
        return <AiAssistantAction {...props} integrations={integrations} />
      }
      if (action.type === 'integration') {
        return <RunIntegrationAction {...props} integrations={integrations} />
      }
      if (action.type === 'operator') {
        return <CallOperatorAction {...props} />
      }
      if (action.type === 'confirm') {
        return <ConfirmAction {...props} />
      }
      if (action.type === 'choose') {
        return <ChooseAction {...props} />
      }
      if (action.type === 'exit_topic') {
        return <ExitTopicAction {...props} enableFeedback={enableFeedback} />
      }
      if (action.type === 'slot') {
        return (
          <SlotFillingAction
            {...props}
            entities={entities}
            topics={topics}
            onChangeSlotDefinition={onChangeSlotDefinition}
          />
        )
      }
      if (action.type === 'change_scenario') {
        return <ChangeScenarioAction {...props} topics={topics} />
      }
      if (action.type === 'faq') {
        return <ClassifyFaqAction {...props} faqs={faqs} />
      }
      if (action.type === 'generate_answer') {
        return <GenerateAnswerAction {...props} faqs={faqs} faqFiles={faqFiles} />
      }
      if (action.type === 'choose_scenario') {
        return (
          <ChooseScenarioAction
            {...props}
            domains={domains}
            topics={topics}
            onClickImageReset={onClickImageReset}
            onChangeImageFile={onChangeChooseScenarioImageFile}
          />
        )
      }
      if (action.type === 'rerun_scenario') {
        return <RerunScenarioAction {...props} />
      }
      if (action.type === 'item_list') {
        return <ItemListAction {...props} />
      }
      if (action.type === 'choose_list') {
        return <ChooseListAction {...props} />
      }
      if (action.type === 'carousel') {
        return (
          <CarouselAction
            {...props}
            onClickImageReset={onClickImageReset}
            onChangeCarouselImageFile={onChangeCarouselImageFile}
          />
        )
      }
      if (action.type === 'carousel_list') {
        return <CarouselListAction {...props} />
      }
      if (action.type === 'form') {
        return <FormAction {...props} />
      }
      if (action.type === 'register_task') {
        return <RegisterTaskAction {...props} tasks={tasks} />
      }
      if (action.type === 'abort_task') {
        return <AbortTaskAction {...props} />
      }
      if (action.type === 'complete_task') {
        return <CompleteTaskAction {...props} />
      }
      if (action.type === 'variable') {
        return <VariableAction {...props} faqs={faqs} />
      }
      if (action.type === 'log') {
        return <LogAction {...props} />
      }
      return null
    })

    return (
      <div className="actions">
        {actionNodes}
        <AddNewAction
          onAdd={this.onAdd(fields, fields.length - 1)}
          keepActive={true}
          restrictedActions={restrictedActions}
          disabled={maxLength ? actions.length >= maxLength : false}
        />
        {submitFailed && error && <div className="error">{t(error)}</div>}
      </div>
    )
  }

  render() {
    const {
      topicType,
      actions,
      integrations,
      domains,
      topics,
      entities,
      faqs,
      faqFiles,
      tasks,
      isLoadedImage,
      onChangeImageMethod,
      onChangeImageFile,
      onClickImageReset,
      onChangeChooseScenarioImageFile,
      onChangeCarouselImageFile,
      userVariables,
      chatbotConstants,
      variables,
      maxLength,
      restrictedActions,
      isValidateCondition,
      onChangeSlotDefinition,
      onChangeImageActionType,
      onError,
      onChangeConditionType,
      condition_groups,
      enableFeedback,
    } = this.props
    return (
      <FieldArray
        topicType={topicType}
        name="actions"
        actions={actions}
        integrations={integrations}
        domains={domains}
        topics={topics}
        component={this.renderActions}
        entities={entities}
        faqs={faqs}
        faqFiles={faqFiles}
        tasks={tasks}
        isLoadedImage={isLoadedImage}
        onChangeImageMethod={onChangeImageMethod}
        onChangeImageFile={onChangeImageFile}
        onClickImageReset={onClickImageReset}
        onChangeChooseScenarioImageFile={onChangeChooseScenarioImageFile}
        onChangeCarouselImageFile={onChangeCarouselImageFile}
        userVariables={userVariables}
        chatbotConstants={chatbotConstants}
        variables={variables}
        maxLength={maxLength}
        restrictedActions={restrictedActions}
        isValidateCondition={isValidateCondition}
        onChangeSlotDefinition={onChangeSlotDefinition}
        onChangeImageActionType={onChangeImageActionType}
        onError={onError}
        onChangeConditionType={onChangeConditionType}
        condition_groups={condition_groups}
        enableFeedback={enableFeedback}
      />
    )
  }
}

export default ActionList
