import lodash from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { CheckboxField, MultipleSelectField, TextAreaField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'

import AbstractAction from './AbstractAction'
import TextField from '../common/fields/TextField'

export class GenerateAnswerAction extends AbstractAction {
  static propTypes = {
    ...AbstractAction.propTypes,
    faqs: PropTypes.array.isRequired,
    faqFiles: PropTypes.array.isRequired,
  }

  static validate = action => {
    const errors = super.validate(action)

    if (!action.sources || action.sources.length === 0) {
      errors.sources = 'validate.multipleSelectListRequired'
    }

    return errors
  }

  getTitle() {
    return this.context.t('topic.actionList.generate_answer.title')
  }
  getType() {
    return 'user'
  }

  renderBody() {
    const { t } = this.context
    const { name, faqs, faqFiles, action } = this.props

    const sources = []
    if (lodash.some(faqFiles, faqFile => !lodash.isEmpty(faqFile.tags))) {
      const tags = lodash.reduce(
        faqFiles,
        (tags, x) => {
          tags.push(...x.tags)
          return tags
        },
        []
      )
      tags.push(...action.tags)
      sources.push(
        ...lodash
          .uniq(tags)
          .sort()
          .map(tag => ({ id: `tag:${tag}`, name: tag }))
      )
    } else {
      sources.push(...faqs.map(faq => ({ id: `faq:${faq.id}`, name: faq.name })))
      sources.push(...faqFiles.map(faqFile => ({ id: `faq_file:${faqFile.id}`, name: faqFile.name })))
    }

    return (
      <div className="faq">
        {/* question text  */}
        <div className="row">
          <LabelWithTooltip
            htmlFor={`${name}.content`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.generate_answer.question"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <TextField
              name={`${name}.content`}
              className="form-control"
              placeholder={t('topic.actionList.generate_answer.questionPlaceHolder')}
            />
          </div>
        </div>

        {/* faq file select  */}
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.sources`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.generate_answer.sources"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.sources`}
              component={MultipleSelectField}
              items={sources}
              valueKey="id"
              displayKey="name"
              order="asc"
              placeholder={t('topic.actionList.generate_answer.sourcesPlaceholder')}
              noOptionsMessage={() => t('topic.actionList.generate_answer.noOptionsMessage')}
              hasAllSelectButton={true}
            />
          </div>
        </div>
        <div className="form-group form-inline mt-1">
          <Field
            type="checkbox"
            name={`${name}.use_customized_prompt`}
            id={`${name}.use_customized_prompt`}
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip
            htmlFor={`${name}.use_customized_prompt`}
            className="dm-checkbox"
            name="topic.actionList.generate_answer.customizedPrompt.useCustomizedPrompt"
          />
        </div>
        {action.use_customized_prompt && (
          <div className="form-group with-indent">
            <Field
              name={`${name}.prompt`}
              className="form-control"
              placeholder={t('topic.actionList.generate_answer.customizedPrompt.default')}
              component={TextAreaField}
            />
          </div>
        )}
        <div className="form-group form-inline mt-1">
          <Field
            type="checkbox"
            name={`${name}.skip_related_sources`}
            id={`${name}.skip_related_sources`}
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip
            htmlFor={`${name}.skip_related_sources`}
            className="dm-checkbox"
            name="topic.actionList.generate_answer.skipRelatedSources"
          />
        </div>
        <div className="form-group form-inline mt-1">
          <Field
            type="checkbox"
            name={`${name}.enable_recommendation`}
            id={`${name}.enable_recommendation`}
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip
            htmlFor={`${name}.enable_recommendation`}
            className="dm-checkbox"
            name="topic.actionList.generate_answer.enableRecommendation"
          />
        </div>
      </div>
    )
  }
}

export default GenerateAnswerAction
