import lodash from 'lodash'

const initialState = {
  accounts: [],
  users: [],
  applications: [],
  bots: [],
  domains: [],
  topics: [],
  endpoints: [],
  entities: [],
  faqs: [],
  faq_files: [],
  faq_categories: [],
  faq_items: [],
  generator: [],
  integrations: [],
  tasks: [],
  interruptions: [],
  sample_templates: [],
  email_addresses: [],
  email_domains: [],
  oauth_clients: [],
  operator: [],
  threads: [],
  prediction_failure_logs: [],
  learning_exclusion_words: [],
  plans: [],
  condition_groups: [],
  delivery_jobs: [],
}

export default (state = initialState, action) => {
  const updateTargetState = (state, action) => {
    if (action.type === 'REQUEST_API') {
      return [...state, action.identifier]
    }

    if (action.type === 'CLOSE_API') {
      return lodash.without(state, action.identifier)
    }

    return state
  }

  if (!lodash.includes(['REQUEST_API', 'CLOSE_API'], action.type)) {
    return state
  }

  return {
    ...state,
    [action.target]: updateTargetState(state[action.target], action),
  }
}
